import React, { useEffect } from "react";
import AdminLayout from "./../../../Layouts/AdminLayout";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, FormControl, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { getRequest, putRequest, uploadURL } from "../../../api";
import { useState } from "react";
import ReactSelect from "react-select";
import { ThreeDots } from "react-loader-spinner";

const UpdateResult = () => {
  let resultId = "";
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;
  const resultIdParams = pathname.split("/").pop();
  if (location?.state?.resultId === "" || resultIdParams === "") {
    history.push("/Users");
  } else if (location?.state?.resultId) {
    resultId = location?.state?.resultId;
  } else {
    resultId = resultIdParams;
  }
  const [isLoading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [kit, setKit] = useState(null);
  const [status, setStatus] = useState("");
  const [note, setNote] = useState("");
  const [statusOptions, setStatusOptions] = useState();

  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const getResultById = async (resultId) => {
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await getRequest(
        `/api/secure/result/get-result-by-id`,
        token,
        {
          testId: resultId,
        }
      );
      setNote(response.result.data.result.note);
      setResult(response.result.data.result);
      setKit(response.result.data.result.kitId);
      const uniqueColorResultCases = new Set();
      const mappedOptions =
        response.result.data.result.kitId?.resultCodes.reduce(
          (options, option) => {
            if (!uniqueColorResultCases.has(option.colorResultCase)) {
              uniqueColorResultCases.add(option.colorResultCase);
              options.push({
                value: option.colorResultCase,
                label: option.colorResultCase,
              });
            }
            return options;
          },
          []
        );
      setStatusOptions(mappedOptions);
      setLoading(false);
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };
  const updateResult = async (event) => {
    event.preventDefault();
    console.log("Selected Test Result:", status?.value);
    console.log("Note:", note);
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await putRequest(
        "/api/secure/result/update-result",
        token,
        {
          resultId,
          testResult: status?.value,
          note,
        }
      );
      if (response?.result?.status === 200) {
        toast.success("Test Result Updated", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        // window.location.assign("/Users");
      }
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };
  const parseColor = (color) => {
    try {
      const parsedColor = JSON.parse(color);
      const [r, g, b] = parsedColor;
      return `rgb(${r},${g},${b})`;
    } catch (error) {
      console.error("Error parsing color:", error);
      return "";
    }
  };

  const parseCode = (code) => {
    try {
      const parsedCode = JSON.parse(code);
      const { r, g, b } = parsedCode;
      return `rgb(${r},${g},${b})`;
    } catch (error) {
      console.error("Error parsing code:", error);
      return "";
    }
  };
  useEffect(() => {
    getResultById(resultId);
  }, [resultId, location, history]);

  return (
    <AdminLayout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updateResult}
            >
              {(result.status === "Detected" ||
                result.status === "Not Detected") && (
                <div className="bg-white">
                  <div className="d-flex align-items-center justify-content-between bg-white p-2">
                    <p className="m-0">System Detected Color</p>
                    <div
                      style={{
                        width: 50,
                        height: 50,
                        backgroundColor: parseColor(result.detectedColor),
                      }}
                    ></div>
                  </div>
                  <hr />
                  {result.testImages.length > 0 && (
                    <Row className="bg-white m-0">
                      {result.testImages.map((image, index) => (
                        <Col key={image._id}>
                          <h5>Sample Test {index + 1}:</h5>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <img
                              src={uploadURL + image.image}
                              style={{
                                height: 505,
                                // width:118,
                                objectFit: "cover",
                              }}
                              alt="Test Image"
                            />
                            <span
                              style={{
                                left: `${image.colorLocation.x}px`,
                                top: `${image.colorLocation.y}px`,
                                height: 15,
                                width: 15,
                                borderRadius: "50%",
                                position: "absolute",
                                backgroundColor: "#000",
                              }}
                            ></span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="m-0">
                              Detected code for Image {index + 1}
                            </p>
                            <div
                              style={{
                                width: 50,
                                height: 50,
                                backgroundColor: parseCode(image.code),
                              }}
                            ></div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </div>
              )}
              <Row className="bg-white py-3 m-0 justify-content-center">
                {result?.testResult == undefined &&
                result?.status !== "Not Detected" &&
                result?.testImages?.length > 0 ? (
                  <Col xl={12} sm={12} className="text-center mb-3">
                    Test has not been detected yet!
                  </Col>
                ) : (
                  <>
                    <Col xl={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="d-block text-center">
                          Test Result
                        </Form.Label>
                        <ReactSelect
                          placeholder="Select Test Results"
                          onChange={statusChange}
                          options={statusOptions}
                          defaultValue={
                            result?.testResult !== undefined
                              ? {
                                  value: result?.testResult,
                                  label: `${result?.testResult}`,
                                }
                              : ""
                          }
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={12} sm={12} className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label className="">Note</Form.Label>
                        <FormControl
                          name="note"
                          as="textarea"
                          placeholder="Please add the note."
                          value={note != undefined && note ? note : ""}
                          onChange={(e) => setNote(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}

                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Update</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default UpdateResult;
