import React from "react";
import { Button, Col, Container, Form, Modal } from "react-bootstrap";
import AdminLayout from "../../../Layouts/AdminLayout";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPencilAlt,
  faPrint,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useHistory, useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const UpdateExistingKitsHandler = ({ groupId }) => {
  const location = useLocation();
  const history = useHistory();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = React.useState(true);
  const [tableData, setTableData] = useState();
  const [error, setError] = useState();
  const getAllKits = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      // console.log("token", token);
      // var params = props.location.search.slice(5);
      const kitResponse = await getRequest(
        `/api/secure/kit/kits-by-group`,
        token,{
          groupId
        }
      );
      // getKitGroup(kitResponse.result.data.kits);
      // const groupedKits = kitResponse.result.data.kits.reduce((acc, kit) => {
      //   const manufacturingDate = moment(kit.manufacturingDate).format(
      //     "DD-MM-YYYY"
      //   );
      //   const createdDate = moment(kit.createdAt).format("DD-MM-YYYY");
      //   const key = manufacturingDate + "-" + createdDate; // Combine manufacturingDate and createdDate as a key

      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(kit);
      //   return acc;
      // }, {});
      setTableData(kitResponse.result.data.kits);
      setTableHead([
        {
          name: "Name",
          selector: (row) => row.latestKit.name,
        },
        {
          name: "Total Kits",
          selector: (row, index) => row.kitCount,
        },
        {
          name: "Bar Code",
          selector: (row, index) => row.latestKit.barCodeId,
        },
        {
          name: "Manufacturing Date",
          selector: (row) =>
            moment(row.latestKit.manufacturingDate).format("DD-MM-YYYY"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="success"
                onClick={() => {
                  history.push({
                    pathname: `/printQR`,
                    state: {
                      createdAt: row.latestKit.createdAt,
                      manufacturingDate: row.latestKit.manufacturingDate,
                      groupId,
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faPrint} />
              </Button>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => updateKitsByGroupId(row, index, column, id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                className="del_btn"
                size="sm"
                variant="danger"
                onClick={() => deleteKitsByGroupId(row, index, column, id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);
      setPending(false);
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };
  const updateKitsByGroupId = async (row, index, column, id) => {
    // console.log("row", row.latestKit);
    const { _id, groupId, isEditable, manufacturingDate } = row.latestKit;
    if (isEditable) {
      history.push({
        pathname: `/updateKits`,
        state: { _id, groupId, manufacturingDate },
      });
    } else {
      setError("Kits is not editable, because the QR has been Printed");
    }
  };
  const deleteKitsByGroupId = async (row, index, column, id) => {
    const { groupId, manufacturingDate } = row.latestKit;
    try {
      setPending(true);
      const token = localStorage.getItem("TOKEN");
      const response = await postRequestForm(
        `/api/secure/kit/delete-kits`,
        token,
        { manufacturingDate, groupId, isDeleteOne: false, kitId: null }
      );
      // console.log(response);
      if (response.result.status === 200) {
        toast.success("Kits has been deleted", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("error", error);
    }finally{
      setPending(false);
      getAllKits();
    }
  };

  useEffect(() => {
    getAllKits();
  }, []);
  return (
    <>
      {!pending ? (
        <>
          <DataTable
            columns={tableHead}
            data={_.sortBy(tableData, "total").reverse()}
            style={{
              borderRadius: "10px",
            }}
            pagination
            progressPending={pending}
          />
          {error ? (
            <>
              <div className="alert alert-danger">{error}</div>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        <div className="loadingContainer text-center d-flex justify-content-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default UpdateExistingKitsHandler;
