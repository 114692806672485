import AuthImages from "./Auth/images";
export default {
  __SITE_ID: "007",
  SITE_SLOGAN: "#",
  SITE_TITLE: "QUASR",
  SITE_LOGO: AuthImages.LOGO,
  FB_link: "#",
  TW_link: "#",
  Phone: "#",
  Email: "#",
  Printrest: "#",
  Site_Link: "#",
  Copyright_text: `©${new Date().getFullYear()} QUASR`,
};
