import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Tab, Tabs, Form, Row, Col, Container } from "react-bootstrap";
import AdminLayout from "./../../../Layouts/AdminLayout";
import UpdateExistingKitsHandler from './UpdateExistingKitsHandler';

const EditKitsByGroup = () => {
  let groupId = "";
  const location = useLocation();
  const history = useHistory();
  if (location?.state?._id === "") {
    history.push("/kitGroups");
  } else {
    groupId = location?.state?._id;
  }

  return (
    <AdminLayout>
      <section id="create_kits_by_group_form_section" className="py-5 ">
        <Container>
          <UpdateExistingKitsHandler groupId={groupId} />
        </Container>
      </section>
    </AdminLayout>
  );
};

export default EditKitsByGroup;
