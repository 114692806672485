import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminImages from "../../Constants/Admin/images";
import SiteSettings from "../../Constants/SiteSettings";
import routes from "../../routes";
export const Header = ({loader}) => {
  const getAdminRoutesChild = (routes) => {
    return routes.map((prop, key) => {
      if (prop.show == "yes") {
        if (prop?.subMenu) {
          return (
            <NavDropdown
              key={key}
              renderMenuOnMount
              as="li"
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
            >
              {getAdminRoutesChild(prop.subMenu)}
            </NavDropdown>
          );
        } else {
          return (
            <Nav.Item as="li" key={key}>
              <Link className="nav-link" to={prop.path}>
                {prop.name}
              </Link>
            </Nav.Item>
          );
        }
      }
    });
  };
  const getAdminRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.show == "yes") {
        if (prop?.subMenu) {
          return (
            <NavDropdown
              key={key}
              menuRole="menu"
              as="li"
              renderMenuOnMount
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
            >
              {getAdminRoutesChild(prop.subMenu)}
            </NavDropdown>
          );
        } else {
          return (
            <Nav.Item as="li" key={key}>
              <Link className="nav-link" to={prop.path}>
                {prop.name}
              </Link>
            </Nav.Item>
          );
        }
      }
    });
  };

  return (
    <>
      <div className="sidebar" style={{
        display:!loader?'block':'none'
      }}>
        <div className="logo-details">
          <img src={AdminImages.LOGO} alt="" />
        </div>
        <Nav id="main_menu" as="ul" className="me-auto flex-column">
          {getAdminRoutes(routes)}
        </Nav>
        <p className="copyright_para">{SiteSettings.Copyright_text}</p>
      </div>
    </>
  );
};
