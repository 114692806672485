import React, { useState, useEffect } from "react";
import AdminLayout from "./../../../Layouts/AdminLayout";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import {  getRequest, putRequest } from "./../../../api";
import { ThreeDots } from "react-loader-spinner";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { toast } from 'react-toastify';

const Privacy = () => {
  const [isLoading, setLoading] = useState(true);

  const [page, setPage] = useState([]);

  const [editor, setEditor] = useState();
  const onChangeEditorHandle = (editorState) => {
    setEditor(editorState);
  };
  const updatePageHandler = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('TOKEN')
      const response = await putRequest(
        "/api/secure/page/update-policy",
        token,
        {
          pageName:"Privacy Policy",
          content:draftToHtml(convertToRaw(editor.getCurrentContent()))
        }
      );

      // console.log("status", response);
      if (response?.result?.status === 200) {
        toast.success("Privacy Policy Page Updated", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        // window.location.assign("/Users");
      }
    } catch (error) {
      console.log("Update Page APi error", error.message);
    }
  };
  const getPageByName = async (pageName) => {
    try {
      const response = await getRequest("/api/secure/page/policy", "", {
        pageName,
      });
      if (response?.result?.status === 200) {
        console.log("policyPage", response?.result?.data?.policyPage);
        setPage(response?.result?.data?.policyPage);
        const contentBlock = htmlToDraft(
          response?.result?.data?.policyPage?.content
        );
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("Get Page APi error", error.message);
    }
  };
  useEffect(() => {
    getPageByName("Privacy Policy");
  }, []);

  return (
    <AdminLayout>
      <section className="py-5">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updatePageHandler}
            >
              <Row>
                <Col sm={12}>
                  <Form.Label>
                    <b>Privacy Policy Content</b>
                  </Form.Label>
                  <Editor
          editorState={editor}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={onChangeEditorHandle}
                  />
                </Col>
                <Col xl={12} sm={12} className="text-center mt-4">
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
}


export default Privacy