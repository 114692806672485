import React, { useEffect, useRef, useState, forwardRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getRequest, uploadURL } from "../../../api";
import moment from "moment";

const ComponentToBePrint = forwardRef(({ result, loading }, ref) => {
  console.log("result", result);
  const parseColor = (color) => {
    try {
      const parsedColor = JSON.parse(color);
      const [r, g, b] = parsedColor;
      return `rgb(${r},${g},${b})`;
    } catch (error) {
      console.error("Error parsing color:", error);
      return "";
    }
  };

  const parseCode = (code) => {
    try {
      const parsedCode = JSON.parse(code);
      const { r, g, b } = parsedCode;
      return `rgb(${r},${g},${b})`;
    } catch (error) {
      console.error("Error parsing code:", error);
      return "";
    }
  };
  useEffect(() => {
    loading(result);
  }, [result]);
  return (
    <div
      className="d-flex justify-content-center align-items-center vh-100 overflow-hidden"
      ref={ref}
    >
      {result ? (
        <div className="bg-light rounded-4 p-5">
          <h2 className="text-center pb-2">{result.name} Result</h2>

          {(result.status === "Detected" ||
            result.status === "Not Detected") && (
            <>
              <div className="d-flex justify-content-between">
                <p className="p-0 w-50">Status: {result.status}</p>

                <p className="m-0 w-50">
                  Created Date:{" "}
                  {moment(result.createdDate).format("DD-MM-YYYY")}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="p-0 w-50">
                  Test Result:{" "}
                  {result.testResult ? result.testResult : result.status}
                </p>

                <p className="m-0 w-50">Helping: {result.helping}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="p-0 w-50">Name: {result.name}</p>

                <p className="m-0 w-50">
                  Age: {moment().diff(moment(result.dob), "years")}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="p-0 w-50">Kit Name: {result.kitId.name}</p>

                <p className="m-0 w-50">Kit S/N: {result.kitId.barCodeId}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="p-0 w-50">
                  Kit Manufaturing:{" "}
                  {moment(result.kitId.manufacturingDate).format("DD-MM-YYYY")}
                </p>

                <p className="m-0 w-50">
                  Kit Expire: {moment(result.kitId.expire).format("DD-MM-YYYY")}
                </p>
              </div>

              {result.testImages.length > 0 && (
                <>
                  <h3 className="mb-4">Test Images:</h3>
                  <div className="d-flex justify-content-between ">
                    {result.testImages.map((image, index) => (
                      <div key={image._id} className="px-4">
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <img
                            src={uploadURL + image.image}
                            style={{
                              height: 300,
                              // width:118,
                              objectFit: "cover",
                            }}
                            className="rounded-4"
                            alt="Test Image"
                          />
                          {/* <span
                                style={{
                                  left: `${image.colorLocation.x-12.5}px`,
                                  top: `${image.colorLocation.y-12.5}px`,
                                  height: 15,
                                  width: 15,
                                  borderRadius: "50%",
                                  position: "absolute",
                                  backgroundColor: "#000",
                                }}
                              ></span> */}
                        </div>
                        <div className="d-flex  justify-content-between align-items-center">
                          <p className="m-0 me-3 fw-semibold">
                            Detected code for Image {index + 1}
                          </p>
                          <div
                            style={{
                              backgroundColor: parseCode(image.code),
                              color: "#fff",
                              height: "45px",
                              width: "45px",
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr />
                  <div className="d-flex px-4 justify-content-between align-items-center">
                    <p className="m-0 me-3 fw-semibold">
                      System Detected Color
                    </p>
                    <div
                      style={{
                        backgroundColor: parseColor(result.detectedColor),
                        height: "45px",
                        width: "45px",
                        borderRadius: "50%",
                      }}
                    ></div>
                  </div>
                  {result.isCheckByAdmin ? (
                    <p className="m-0 px-4 fw-semibold">{result.note}</p>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}
          {result.status === "Not Active" && (
            <>
              {/* <p>Cron ID: {result.cronId}</p> */}
              <p>Created Date: {result.createdDate}</p>
              {/* Render additional fields for the "Not Active" status */}
              {/* e.g., result.testImages, etc. */}
            </>
          )}
          <hr />
        </div>
      ) : (
        ""
      )}
    </div>
  );
});
const ViewResults = () => {
  const componentRef = useRef();

  let resultId = "";
  const location = useLocation();
  const pathname = location.pathname;
  const resultIdParams = pathname.split("/").pop();
  const history = useHistory();
  if (resultIdParams === "") {
    history.goBack();
  } else {
    resultId = resultIdParams;
  }
  const [result, setResult] = useState();
  const [isLoading, setLoading] = useState(true);

  const getResult = async (resultId) => {
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await getRequest(
        "/api/secure/result/get-result-by-id",
        token,
        {
          testId: resultId,
        }
      );
      if (response?.result?.status === 200) {
        setResult(response.result.data.result);
      }
    } catch (error) {
      console.log("Get Resutls APi error", error.message);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      history.goBack();
    },
  });

  useEffect(() => {
    getResult(resultId);
  }, [resultId]);

  return (
    <div>
      {result && (
        <ComponentToBePrint
          result={result}
          ref={componentRef}
          loading={(result) => {
            result && setLoading(false);
          }}
        />
      )}
      {/* {!isLoading ? handlePrint() : ""} */}
    </div>
  );
};

export default ViewResults;
