import React from "react";

import SiteSettings from "./Constants/SiteSettings";
import UseDocumentTitle from "./UseDocumentTitle";
import Dashboard from "./Views/Admin/Dashboard";
import Profile from "./Views/Admin/Profile";
import Login from "./Views/Auth/Login";
import OTP from "./Views/Auth/OTP";
import ForgetPassword from "./Views/Auth/ForgetPassword";
import SetNewPassword from "./Views/Auth/SetNewPassword";
import AboutUs from "./Views/Admin/Pages/AboutUs";
import Faqs from "./Views/Admin/Pages/Faqs";
import Terms from "./Views/Admin/Pages/Terms";
import Privacy from "./Views/Admin/Pages/Privacy";
import Users from "./Views/Admin/Users/Users";
import AddUsers from "./Views/Admin/Users/AddUsers";
import Kits from "./Views/Admin/Kits/Kits";
import AddKitGroup from "./Views/Admin/Kits/AddKitGroup";
import Results from "./Views/Admin/Results/Results";
import ViewResults from "./Views/Admin/Results/ViewResults";
import EditUser from "./Views/Admin/Users/EditUser";
import Logout from "./Views/Logout";
import AllMedia from "./Views/Admin/Media/AllMedia";
import CreateMedia from "./Views/Admin/Media/CreateMedia";
import EditMedia from "./Views/Admin/Media/EditMedia";
import KitGroups from "./Views/Admin/Kits/KitGroups";
import EditKitGroup from "./Views/Admin/Kits/EditKitGroup";
import AddKitsByGroup from "./Views/Admin/Kits/AddKitsByGroup";
import EditKitsByGroup from "./Views/Admin/Kits/EditKitsByGroup";
import UpdateKits from "./Views/Admin/Kits/UpdateKits";
import PrintQR from "./Views/Admin/Kits/PrintQR";
import UpdateResult from "./Views/Admin/Results/UpdateResult";
import DeletedUsers from "./Views/Admin/Users/DeletedUsers";
import _ from "underscore";
import DeletedKits from "./Views/Admin/Kits/Deletedkits";
function _Login() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Login`);
  return <Login />;
}
function _OTP() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | OTP`);
  return <OTP />;
}
function _ForgetPassword() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Forget Password`);
  return <ForgetPassword />;
}
function _SetNewPassword() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | New Password`);
  return <SetNewPassword />;
}
function _Dashboard() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Dashboard`);
  return <Dashboard />;
}
function _Profile() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Profile`);
  return <Profile />;
}
function _AboutUs() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | About Us`);
  return <AboutUs />;
}
function _Faqs() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | FAQs`);
  return <Faqs />;
}
function _Terms() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Terms & Conditions`);
  return <Terms />;
}
function _Privacy() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Privacy Policy`);
  return <Privacy />;
}
function _Users() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | List all Users`);
  return <Users />;
}
function _addUser() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Create New User`);
  return <AddUsers />;
}
function _Kits() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | List all Kits`);
  return <Kits />;
}
function _Results() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | List all Results`);
  return <Results />;
}
function _viewResults() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | View Result`);
  return <ViewResults />;
}
function _updateResults() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Update Result`);
  return <UpdateResult />;
}
function _editSingleUser() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit User`);
  return <EditUser />;
}
function _deletedUsers() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Deleted User`);
  return <DeletedUsers />;
}
function _allMedia() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | All Media`);
  return <AllMedia />;
}
function _createMedia() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Create Media`);
  return <CreateMedia />;
}
function _editMedia() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Media`);
  return <EditMedia />;
}
function _logout() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Logout`);
  return <Logout />;
}
function _editKitGroup() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Kit Group`);
  return <EditKitGroup />;
}
function _KitGroups() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Kit Groups`);
  return <KitGroups />;
}
function _addKitGroup() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Create Kit Groups`);
  return <AddKitGroup />;
}
function _updateKitsByGroup() {
  UseDocumentTitle(
    `${SiteSettings.SITE_TITLE} | Admin | Update Kits Against Group`
  );
  return <EditKitsByGroup />;
}
function _addkitsByGroup() {
  UseDocumentTitle(
    `${SiteSettings.SITE_TITLE} | Admin | Create Kits Against Group`
  );
  return <AddKitsByGroup />;
}
function _updateKits() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Update Kits`);
  return <UpdateKits />;
}
function _printQR() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Print QR`);
  return <PrintQR />;
}
function _archivedKits() {
  UseDocumentTitle(`${SiteSettings.SITE_TITLE} | Admin | Archived Kits`);
  return <DeletedKits />;
}
// _printQR
// _updateKits
var routes = [
  //Auth
  {
    path: "/login",
    name: "Login",
    icon: "",
    component: _Login,
    layout: "",
    show: "no",
  },
  {
    path: "/otp",
    name: "OTP",
    icon: "",
    component: _OTP,
    layout: "",
    show: "no",
  },
  {
    path: "/forgetPassword",
    name: "Forget Password",
    icon: "",
    component: _ForgetPassword,
    layout: "",
    show: "no",
  },
  {
    path: "/setPassword",
    name: "Set Password",
    icon: "",
    component: _SetNewPassword,
    layout: "",
    show: "no",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "",
    component: _Dashboard,
    layout: "",
    show: "yes",
  },
  {
    path: "",
    name: "Pages",
    icon: "",
    component: "",
    layout: "",
    show: "yes",
    subMenu: [
      {
        path: "/AboutUs",
        name: "About Us",
        icon: "",
        component: _AboutUs,
        layout: "",
        show: "yes",
      },
      {
        path: "/faqs",
        name: "FAQs",
        icon: "",
        component: _Faqs,
        layout: "",
        show: "yes",
      },
      {
        path: "/terms",
        name: "Terms & Conditions",
        icon: "",
        component: _Terms,
        layout: "",
        show: "yes",
      },
      {
        path: "/privacy",
        name: "Privacy Policy",
        icon: "",
        component: _Privacy,
        layout: "",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Users",
    icon: "",
    component: _Users,
    layout: "",
    show: "yes",
    subMenu: [
      {
        path: "/Users",
        name: "All Users",
        icon: "",
        component: _Users,
        layout: "",
        show: "yes",
      },
      {
        path: "/editSingleUser",
        name: "Edit Users",
        icon: "",
        component: _editSingleUser,
        layout: "",
        show: "no",
      },
      {
        path: "/addUser",
        name: "Create User",
        icon: "",
        component: _addUser,
        layout: "",
        show: "yes",
      },
      {
        path: "/deletedUsers",
        name: "Deleted Users",
        icon: "",
        component: _deletedUsers,
        layout: "",
        show: "yes",
      },
      {
        path: "/profile",
        name: "Profile",
        icon: "",
        component: _Profile,
        layout: "",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Kits",
    icon: "",
    component: _Kits,
    layout: "",
    show: "yes",
    subMenu: [
      {
        path: "/kits",
        name: "All Kits",
        icon: "",
        component: _Kits,
        layout: "",
        show: "yes",
      },
      {
        path: "/archivedKits",
        name: "All Archived Kits",
        icon: "",
        component: _archivedKits,
        layout: "",
        show: "yes",
      },
      {
        path: "/kitGroups",
        name: "All Kit Groups",
        icon: "",
        component: _KitGroups,
        layout: "",
        show: "yes",
      },
      {
        path: "/addKitGroup",
        name: "Create Kit Group",
        icon: "",
        component: _addKitGroup,
        layout: "",
        show: "yes",
      },
      {
        path: "/editKitGroup",
        name: "Edit Kit Group",
        icon: "",
        component: _editKitGroup,
        layout: "",
        show: "no",
      },
      {
        path: "/updateKitsByGroup",
        name: "Edit Kits against Group",
        icon: "",
        component: _updateKitsByGroup,
        layout: "",
        show: "no",
      },
      {
        path: "/addkitsByGroup",
        name: "Create New Kitsagainst Group",
        icon: "",
        component: _addkitsByGroup,
        layout: "",
        show: "no",
      },
      {
        path: "/updateKits",
        name: "Update Kits",
        icon: "",
        component: _updateKits,
        layout: "",
        show: "no",
      },
      {
        path: "/printQR",
        name: "Print QR",
        icon: "",
        component: _printQR,
        layout: "",
        show: "no",
      },
    ],
  },
  {
    path: "",
    name: "Media",
    icon: "",
    component: _allMedia,
    layout: "",
    show: "yes",
    subMenu: [
      {
        path: "/allMedia",
        name: "All Media",
        icon: "",
        component: _allMedia,
        layout: "",
        show: "yes",
      },
      {
        path: "/createMedia",
        name: "Create Media",
        icon: "",
        component: _createMedia,
        layout: "",
        show: "yes",
      },
      {
        path: "/editMedia",
        name: "Edit Media",
        icon: "",
        component: _editMedia,
        layout: "",
        show: "no",
      },
    ],
  },
  {
    path: "",
    name: "Results",
    icon: "",
    component: _Results,
    layout: "",
    show: "yes",
    subMenu: [
      {
        path: "/results",
        name: "All Results",
        icon: "",
        component: _Results,
        layout: "",
        show: "yes",
      },
      {
        path: "/viewResult/:resultId",
        name: "View Results",
        icon: "",
        component: _viewResults,
        layout: "",
        show: "no",
      },
      {
        path: "/updateResult/:resultId",
        name: "Update Results",
        icon: "",
        component: _updateResults,
        layout: "",
        show: "no",
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "",
    component: _logout,
    layout: "",
    show: "yes",
  },
];
export default routes;
