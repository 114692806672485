import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import AdminLayout from "./../../../Layouts/AdminLayout";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPencil,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm, uploadURL } from "../../../api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useHistory } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const Results = () => {
  const history = useHistory();
  const [tableHead, setTableHead] = useState();
  const [columns, setColumns] = useState([]);
  const [pending, setPending] = React.useState(true);
  const [tableData, setTableData] = useState();
  const [searchItem, setSearchItem] = useState("");

  const getAllUsers = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      // console.log("token", token);
      // var params = props.location.search.slice(5);
      const response = await getRequest(
        `/api/secure/result/get-results`,
        token
      );

      setTableData(response.result.data.results);
      console.log("Get All Users Response", response.result.data.results);
      setTableHead([
        {
          name: "Test Date",
          selector: (row) => moment(row.createdDate).format("DD-MM-YYYY"),
        },
        {
          name: "Status",
          selector: (row) => row.status,
        },
        {
          name: "User",
          selector: (row) => row.name,
        },
        {
          name: "Result",
          selector: (row) => row.testResult,
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() =>
                  downloadSingleResult(row._id, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() =>
                  editSingleResult(row._id, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </Button>
            </>
          ),
        },
      ]);
      setPending(false);
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };
  const isRowExpandable = (row) => {
    return row.status !== "Not Active";
  };

  // Content of the expanded row (stringified JSON)
  const renderExpandedRow = (row) => {
    const result = row?.data;
    console.log("result", result);
    const parseColor = (color) => {
      try {
        const parsedColor = JSON.parse(color);
        const [r, g, b] = parsedColor;
        return `rgb(${r},${g},${b})`;
      } catch (error) {
        console.error("Error parsing color:", error);
        return "";
      }
    };

    const parseCode = (code) => {
      try {
        const parsedCode = JSON.parse(code);
        const { r, g, b } = parsedCode;
        return `rgb(${r},${g},${b})`;
      } catch (error) {
        console.error("Error parsing code:", error);
        return "";
      }
    };
    return (
      <Container fluid className="py-3">
        {(result.status === "Detected" ||result.status === "Not Detected") && (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-0">System Detected Color</p>
              <div
                style={{
                  width: 50,
                  height: 50,
                  backgroundColor: parseColor(result.detectedColor),
                }}
              ></div>
            </div>
            <hr />
            {result.testImages.length > 0 && (
              <Row>
                {result.testImages.map((image, index) => (
                  <Col key={image._id}>
                    <h5>Sample Test {index + 1}:</h5>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        src={uploadURL + image.image}
                        style={{
                          height: 505,
                          // width:118,
                          objectFit: "cover",
                        }}
                        alt="Test Image"
                      />
                      <span
                        style={{
                          left: `${image.colorLocation.x}px`,
                          top: `${image.colorLocation.y}px`,
                          height: 15,
                          width: 15,
                          borderRadius: "50%",
                          position: "absolute",
                          backgroundColor: "#000",
                        }}
                      ></span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="m-0">Detected code for Image {index + 1}</p>
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          backgroundColor: parseCode(image.code),
                        }}
                      ></div>
                    </div>
                  </Col>
                ))}
              </Row>
            )}
          </>
        )}
      </Container>
    );
  };

  const downloadSingleResult = async (resultId, row, index, column, id) => {
    history.push({
      pathname: `/viewResult/${resultId}`,
    });
  };

  const editSingleResult = async (resultId, row, index, column, id) => {
    history.push({
      pathname: `/updateResult/${resultId}`,
      state: { resultId },
    });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <AdminLayout>
      <section id="users_list" className="pt-5">
        <Container>
          {!pending ? (
            <DataTable
              columns={tableHead}
              data={_.sortBy(tableData, "total").reverse()}
              style={{
                borderRadius: "10px",
              }}
              pagination
              progressPending={pending}
              expandableRows
              expandableRowsComponent={renderExpandedRow}
              expandableRowDisabled={(row) => row.status === "Not Active"||row.status === "Pending"}
              isRowExpandable={isRowExpandable}
            />
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default Results;
