import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Tab, Tabs, Form, Row, Col, Container } from "react-bootstrap";
import AdminLayout from "./../../../Layouts/AdminLayout";
import { getRequest, postRequestForm, putRequest } from "../../../api";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import ExtendExistingKitsHandler from "./ExtendExistingKitsHandler";

const AddKitsByGroup = () => {
  let groupId = "";
  const location = useLocation();
  const history = useHistory();
  if (location?.state?._id === "") {
    history.push("/kitGroups");
  } else {
    groupId = location?.state?._id;
  }

  const [isLoading, setLoading] = useState(true);
  const [Group, setGroup] = useState();

  const createFullyNewKitsHandler = async (e) => {
    e.preventDefault();
    // console.log({
    //   groupId: Group._id,
    //   internalNumber: Group.internalNumber,
    //   testTime: Group.testTime,
    //   steps: Group.steps,
    //   resultCodes: Group.resultCodes,
    //   name: e.target[0].value,
    //   manufacturingDate: e.target[1].value,
    //   expire: e.target[2].value,
    //   status: e.target[3].value,
    //   qty: e.target[4].value,
    //   createType: "new",
    //   ageLimit: Group.ageLimit,
    //   description: Group.description,
    // });
    try {
      setLoading(true)
      const token = localStorage.getItem("TOKEN");
      const response = await postRequestForm(
        "/api/secure/kit/create-kits",
        token,
        {
          groupId: Group._id,
          internalNumber: Group.internalNumber,
          testTime: Group.testTime,
          steps: Group.steps,
          description: Group.description,
          resultCodes: Group.resultCodes,
          name: e.target[0].value,
          manufacturingDate: e.target[1].value,
          expire: e.target[2].value,
          status: e.target[3].value,
          qty: e.target[4].value,
          createType: "new",
          ageLimit: Group.ageLimit,
        }
      );

      // console.log("status", response);
      if (response?.result?.status === 200) {
        toast.success("Kits has been Created", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        window.location.assign("/kits");
      }
    } catch (error) {
      console.log("Update Group APi error", error.message);
    }finally{
      setLoading(false)
    }
    // Your form submission logic here
  };
  const getKitGroupByID = async (groupId) => {
    try {
      const response = await getRequest("/api/secure/kit/kit-group", "", {
        _id: groupId,
      });
      if (response?.result?.status === 200) {
        setGroup(response?.result?.data?.kitGroup);
        setLoading(false);
      }
    } catch (error) {
      console.log("Get Profile APi error", error.message);
    }
  };
  useEffect(() => {
    getKitGroupByID(groupId);
  }, [groupId]);

  return (
    <AdminLayout>
      <section id="create_kits_by_group_form_section" className="py-5 ">
        <Container>
          {!isLoading ? (
            <Tabs defaultActiveKey="createFullyNewKits">
              <Tab eventKey="createFullyNewKits" title="Create Fully New Kits">
                <Form
                  className="form_add_user"
                  encType="multipart/form-data"
                  method="post"
                  onSubmit={createFullyNewKitsHandler}
                >
                  <Row className="bg-white py-3 m-0">
                    <Col xl={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-dark">
                          <strong>Name</strong>
                        </Form.Label>
                        <Form.Control
                          name="name"
                          type="text"
                          placeholder="Name"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-dark">
                          <strong>Manufacturing Date</strong>
                        </Form.Label>
                        <Form.Control
                          name="manufacturingDate"
                          type="date"
                          placeholder="Manufacturing Date"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-dark">
                          <strong>Expire Date</strong>
                        </Form.Label>
                        <Form.Control
                          name="expireDate"
                          type="date"
                          placeholder="Expire Date"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-dark">
                          <strong>Status</strong>
                        </Form.Label>
                        <Form.Control
                          name="status"
                          type="text"
                          value="Not Activated"
                          placeholder="Status"
                          required
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-dark">
                          <strong>Quantity</strong>
                        </Form.Label>
                        <Form.Control
                          name="quantity"
                          type="number"
                          placeholder="Quantity"
                          required
                          min={0}
                          max={50}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={12} sm={12} className="text-center">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </Col>
                  </Row>
                </Form>
              </Tab>
              <Tab eventKey="extendOldKits" title="Extend Old Kits">
                <ExtendExistingKitsHandler groupId={groupId} />
              </Tab>
            </Tabs>
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default AddKitsByGroup;
