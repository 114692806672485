import React, { useEffect } from "react";
import { Header } from "./../Components/Auth/Header";
import { Footer } from "./../Components/Auth/Footer";
import { useHistory } from 'react-router-dom';

const AuthLayout = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("TOKEN") && (localStorage.getItem("ROLE") === "admin")) {
      history.push("/dashboard");
    }
  }, [history])
  
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default AuthLayout;
