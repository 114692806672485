import React, { useState, useEffect, useRef, forwardRef } from "react";
import Barcode from "react-barcode";
import { useHistory, useLocation } from "react-router-dom";
import { getRequest } from "./../../../api";
import { useReactToPrint } from "react-to-print";
import CryptoJS from "crypto-js";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner";

const ComponentToBePrint = forwardRef(({ kits, size, loading }, ref) => {
  console.log("kits", kits);
  const secretPass = "XkhZG4fW2t2W";

  useEffect(() => {
    loading(kits);
  }, [kits]);
  return (
    <div
      div
      className="d-flex flex-wrap justify-content-between ms-1 me-2"
      ref={ref}
    >
      {kits
        ? kits.map((kit, index) => (
            <div key={index}>
              <Barcode
                value={kit.barCodeId}
                format="CODE128"
                height={80}
                width={size}
                displayValue={false}
              />
              <div className="ms-2">
                <p
                  style={{
                    fontSize: 14,
                  }}
                  className="m-0"
                >
                  <b>Kit Name: </b> {kit.name}
                </p>
                <p
                  style={{
                    fontSize: 14,
                  }}
                  className="m-0"
                >
                  <b>S/N:</b> {kit.barCodeId}
                </p>
                <p
                  style={{
                    fontSize: 14,
                  }}
                  className="m-0"
                >
                  <b>M.Date:</b>{" "}
                  {moment(kit.manufacturingDate).format("DD-MM-YYYY")}
                </p>
                <p
                  style={{
                    fontSize: 14,
                  }}
                >
                  <b>B.Before:</b> {moment(kit.expire).format("DD-MM-YYYY")}
                </p>
              </div>
            </div>
          ))
        : ""}
    </div>
  );
});
const PrintQR = () => {
  let groupId = "";
  let manufacturingDate = "";
  let createdAt = "";
  const location = useLocation();
  const history = useHistory();
  console.log("location?.state", location?.state);
  if (location?.state?._id === "") {
    history.push("/kitGroups");
  } else {
    groupId = location?.state?.groupId;
    manufacturingDate = location?.state?.manufacturingDate;
    createdAt = location?.state?.createdAt;
  }
  const [size, setSize] = useState(0.75);
  const [kits, setKits] = useState();
  const [isLoading, setLoading] = useState(true);
  const componentRef = useRef();

  const sendPostRequest = (action) => {
    console.log(action);
  };
  const GetKitsRequest = async (groupId) => {
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await getRequest(
        "/api/secure/kit/get-kit-by-custom-query",
        token,
        {
          groupId,
          manufacturingDate,
          createdAt: createdAt.split("T")[0],
        }
      );
      if (response?.result?.status === 200) {
        setKits(response?.result?.data?.kits);
      }
    } catch (error) {
      console.log("Get Kits APi error", error.message);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      sendPostRequest("canceled");
      history.push({
        pathname: "/addkitsByGroup",
        state: { confirmPrint: "Ask", _id: groupId, manufacturingDate },
      });
    },
  });
  useEffect(() => {
    GetKitsRequest(groupId);
  }, [groupId]);

  return (
    <>
      {kits && (
        <ComponentToBePrint
          kits={kits}
          size={size}
          ref={componentRef}
          loading={(kits) => {
            kits && setLoading(false);
          }}
        />
      )}
      {!isLoading ? (
        handlePrint()
      ) : (
        <div className="loadingContainer text-center d-flex justify-content-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default PrintQR;

// faizan@om-menu.com
// tai@*!}V%CEl

// O1$KzTH*W=#[s
