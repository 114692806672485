import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import routes from "./routes";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-phone-number-input/style.css'
import "./Assets/css/style.scss";
const getRoutes = (routes) =>
  routes.map((prop, key) => {
    if (prop?.subMenu) {
      return prop.subMenu.map((prop, key) => (
        <Route
          exact
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      ));
    } else {
      return (
        <Route
          exact
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    }
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
  <Router>
    <Switch>
      {getRoutes(routes)}
      <Redirect exact from="/" to="/login" />
    </Switch>
  </Router>
);
