import React, { useEffect, useState } from "react";
import { Header } from "./../Components/Admin/Header";
import { Footer } from "./../Components/Admin/Footer";
import AdminImages from "../Constants/Admin/images";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { uploadURL } from "./../api";
import { ThreeDots } from "react-loader-spinner";

const AdminLayout = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [PageLoader, setPageLoader] = useState(true);
  const [Admin, setAdmin] = useState();
  // console.log("location", location.pathname);
  const handleToggler = (e) => {
    e.preventDefault();
    document.querySelector(".sidebar").classList.toggle("active");
    document.querySelector("#main_content").classList.toggle("active");
  };
  useEffect(() => {
    if (
      !localStorage.getItem("TOKEN") &&
      !(localStorage.getItem("ROLE") === "admin")
    ) {
      history.push("/login");
    } else {
      setAdmin(JSON.parse(localStorage.getItem("USER")));
    }

    // if (window.outerWidth == 1199 || window.outerWidth <= 1199) {
    //   document.querySelector(".sidebar").classList.add("active");
    //   document.querySelector("#main_content").classList.add("active");
    // } else if (window.outerWidth > 1199) {
    //   document.querySelector(".sidebar").classList.remove("active");
    //   document.querySelector("#main_content").classList.remove("active");
    // }
    // window.addEventListener(
    //   "resize",
    //   function (event) {
    //     var mainInsideMainContent =
    //       document.querySelector("#main_content>main") !== null;
    //     var navInsideHomeSection =
    //       document.querySelector(".home-section>nav") !== null;
    //     if (mainInsideMainContent && navInsideHomeSection) {
    //       mainInsideMainContent.style.paddingTop = `${navInsideHomeSection.offsetHeight}px`;
    //     }
    //     if (window.outerWidth == 1199) {
    //       document.querySelector(".sidebar").classList.add("active");
    //       document.querySelector("#main_content").classList.add("active");
    //     } else if (window.outerWidth > 1199) {
    //       document.querySelector(".sidebar").classList.remove("active");
    //       document.querySelector("#main_content").classList.remove("active");
    //     }
    //     var mainOnlyInsideMainContent =
    //       document.querySelector(".home-section>main") !== null;
    //     var navOnlyInsideHomeSection =
    //       document.querySelector(".home-section>nav") !== null;
    //     if (mainOnlyInsideMainContent && navOnlyInsideHomeSection) {
    //       mainOnlyInsideMainContent.style.paddingTop = `${navOnlyInsideHomeSection.offsetHeight}px`;
    //     }
    //   },
    //   true
    // );
    setTimeout(() => {
      setPageLoader(false);
      // var mainInsideMainContent =
      //   document.querySelector("#main_content>main") !== null;
      // var navInsideHomeSection =
      //   document.querySelector(".home-section>nav") !== null;
      // if (mainInsideMainContent && navInsideHomeSection) {
      //   mainInsideMainContent.style.paddingTop = `${navInsideHomeSection.offsetHeight}px`;
      // }

      // document.addEventListener("DOMContentLoaded", function (event) {
      //   var mainInsideMainContent =
      //     document.querySelector("#main_content>main") !== null;
      //   var navInsideHomeSection =
      //     document.querySelector(".home-section>nav") !== null;
      //   if (mainInsideMainContent && navInsideHomeSection) {
      //     mainInsideMainContent.style.paddingTop = `${navInsideHomeSection.offsetHeight}px`;
      //   }
      // });
      // window.addEventListener(
      //   "resize",
      //   function (event) {
      //     var mainInsideMainContent =
      //       document.querySelector("#main_content>main") !== null;
      //     var navInsideHomeSection =
      //       document.querySelector(".home-section>nav") !== null;
      //     if (mainInsideMainContent && navInsideHomeSection) {
      //       mainInsideMainContent.style.paddingTop = `${navInsideHomeSection.offsetHeight}px`;
      //     }
      //     if (window.outerWidth == 1199) {
      //       document.querySelector(".sidebar").classList.add("active");
      //       document.querySelector("#main_content").classList.add("active");
      //     } else if (window.outerWidth > 1199) {
      //       document.querySelector(".sidebar").classList.remove("active");
      //       document.querySelector("#main_content").classList.remove("active");
      //     }
      //     var mainOnlyInsideMainContent =
      //       document.querySelector(".home-section>main") !== null;
      //     var navOnlyInsideHomeSection =
      //       document.querySelector(".home-section>nav") !== null;
      //     if (mainOnlyInsideMainContent && navOnlyInsideHomeSection) {
      //       mainOnlyInsideMainContent.style.paddingTop = `${navOnlyInsideHomeSection.offsetHeight}px`;
      //     }
      //   },
      //   true
      // );
    }, 1000);
  }, [history]);
  return (
    <>
      <div
        className="loadingContainer text-center justify-content-center"
        style={{
          display: PageLoader ? "flex" : "none",
        }}
      >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          visible={true}
        />
      </div>
      <Header loader={PageLoader} />
      <section
        className="home-section"
        id="main_content"
        style={{
          display: !PageLoader ? "block" : "none",
        }}
      >
        <nav>
          <div className="sidebar-button">
            <button className="btn p-0" onClick={handleToggler}>
              <img
                src={AdminImages.toggler}
                alt=""
                className="sidebarBtn img-fluid"
              />
            </button>
            <span className="dashboard">
              {location.pathname === "/dashboard"
                ? "Dashboard"
                : location.pathname === "/AboutUs"
                ? "About Us"
                : location.pathname === "/faqs"
                ? "FAQs"
                : location.pathname === "/terms"
                ? "Terms & Conditions"
                : location.pathname === "/privacy"
                ? "Privacy Policy"
                : location.pathname === "/Users"
                ? "Users List"
                : location.pathname === "/addUser"
                ? "Create a new User"
                : location.pathname === "/kits"
                ? "Kits List"
                : location.pathname === "/deletedUsers"
                ? "Deleted Users List"
                : location.pathname === "/addKit"
                ? "Create a new Kit"
                : location.pathname === "/results"
                ? "Results List"
                : location.pathname === "/updateResult"
                ? "Update Result"
                : location.pathname === "/editSingleUser"
                ? "Edit User"
                : location.pathname === "/allMedia"
                ? "All Media"
                : location.pathname === "/createMedia"
                ? "Create Media"
                : location.pathname === "/editMedia"
                ? "Edit Media"
                : location.pathname === "/profile"
                ? "Admin Profile"
                : location.pathname === "/kitGroups"
                ? "Kit Groups"
                : location.pathname === "/editKitGroup"
                ? "Edit Kit Group"
                : location.pathname === "/addkitsByGroup"
                ? "Create Kits"
                : location.pathname === "/updateKitsByGroup"
                ? "Update Kits"
                : location.pathname === "/addKitGroup"
                ? "Create Kit Group"
                : location.pathname === "/updateKits"
                ? "Update Kits"
                : location.pathname === "/archivedKits"
                ? "Archived Kits"
                : ""}
            </span>
          </div>
          {Admin ? (
            <Dropdown id="admin_profile" className="profile-details">
              <Dropdown.Toggle id="dropdown-basic">
                <img src={`${uploadURL}${Admin.profileImage}`} alt="" />
                <span className="admin_name">
                  {Admin.firstName} {Admin?.lastName}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="li">
                  <Link to={"/"}>Dashboard</Link>
                </Dropdown.Item>
                <Dropdown.Item as="li">
                  <Link to={"/profile"}>Profile</Link>
                </Dropdown.Item>
                <Dropdown.Item as="li">
                  <Link to={"/logout"}>Logout</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </nav>
        <main>{children}</main>
      </section>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default AdminLayout;
