import React from "react";
import { Button, Col, Container, Form, Modal } from "react-bootstrap";
import AdminLayout from "../../../Layouts/AdminLayout";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPencilAlt,
  faPlus,
  faPrint,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useHistory } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
const ExtendExistingKitsHandler = ({ groupId }) => {
  const history = useHistory();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = React.useState(true);
  const [tableData, setTableData] = useState();
  const [ExtedingData, setExtedingData] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getAllKits = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      // console.log("token", token);
      // var params = props.location.search.slice(5);
      const kitResponse = await getRequest(
        `/api/secure/kit/kits-by-group`,
        token,
        {
          groupId,
        }
      );
      // const groupedKits = .reduce((acc, kit) => {
      //   const date = moment(kit.manufacturingDate).format("DD-MM-YYYY");
      //   if (!acc[date]) {
      //     acc[date] = [];
      //   }
      //   acc[date].push(kit);
      //   return acc;
      // }, {});
      // console.log("kitResponse.result.data.kits", kitResponse.result.data.kits);
      setTableData(kitResponse.result.data.kits);
      // console.log("Get All Kit Response", kitResponse);
      setTableHead([
        {
          name: "Name",
          selector: (row) => row.latestKit.name,
        },
        {
          name: "Total Kits",
          selector: (row, index) => row.kitCount,
        },
        {
          name: "Bar Code",
          selector: (row, index) => row.latestKit.barCodeId,
        },
        {
          name: "Manufacturing Date",
          selector: (row) =>
            moment(row.latestKit.manufacturingDate).format("DD-MM-YYYY"),
        },
        {
          name: "Actions",
          // button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                title="Extend"
                onClick={() => extendKitsByGroupId(row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="success"
                title="Print"
                onClick={() => {
                  history.push({
                    pathname: `/printQR`,
                    state: {
                      createdAt: row.latestKit.createdAt,
                      manufacturingDate: row.latestKit.manufacturingDate,
                      groupId,
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faPrint} />
              </Button>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                title="Edit"
                onClick={() => updateKitsByGroupId(row, index, column, id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                className="del_btn"
                size="sm"
                variant="danger"
                title="Delete"
                onClick={() => deleteKitsByGroupId(row, index, column, id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);
      setPending(false);
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };
  const extendKitsByGroupId = async (row, index, column, id) => {
    const {
      name,
      description,
      internalNumber,
      ageLimit,
      resultCodes,
      steps,
      expire,
      manufacturingDate,
      status,
      testTime,
      groupId,
    } = row.latestKit;
    setExtedingData({
      name,
      description,
      internalNumber,
      ageLimit,
      resultCodes,
      steps,
      expire,
      manufacturingDate,
      status,
      testTime,
      groupId,
      createType: "extending",
    });
    // console.log("status howe", ExtedingData.status)

    handleShow();
  };
  const updateKitsByGroupId = async (row, index, column, id) => {
    // console.log("row", row.latestKit);
    const { _id, groupId, isEditable, manufacturingDate } = row.latestKit;
    // if (isEditable) {
    history.push({
      pathname: `/updateKits`,
      state: { _id, groupId, manufacturingDate },
    });
    // } else {
    //   setError("Kits is not editable, because the QR has been Printed");
    // }
  };
  const deleteKitsByGroupId = async (row, index, column, id) => {
    const { groupId, manufacturingDate } = row.latestKit;
    try {
      setPending(true);
      const token = localStorage.getItem("TOKEN");
      const response = await postRequestForm(
        `/api/secure/kit/delete-kits`,
        token,
        { manufacturingDate, groupId, isDeleteOne: false, kitId: null }
      );
      // console.log(response);
      if (response.result.status === 200) {
        toast.success("Kits has been deleted", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setPending(false);
      getAllKits();
    }
  };
  const extendKits = async (e) => {
    e.preventDefault();
    try {
      handleClose();
      setPending(true);
      const token = localStorage.getItem("TOKEN");
      const response = await postRequestForm(
        "/api/secure/kit/create-kits",
        token,
        {
          name: ExtedingData.name,
          description: ExtedingData.description,
          internalNumber: ExtedingData.internalNumber,
          ageLimit: ExtedingData.ageLimit,
          resultCodes: ExtedingData.resultCodes,
          steps: ExtedingData.steps,
          expire: ExtedingData.expire,
          manufacturingDate: ExtedingData.manufacturingDate,
          status: "Not Activated",
          testTime: ExtedingData.testTime,
          groupId: ExtedingData.groupId,
          qty: e.target[0].value,
          createType: "extending",
        }
      );

      // console.log("status", response);
      if (response?.result?.status === 200) {
        toast.success("Kits has been Extended", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        window.location.assign("/kits");
      }
    } catch (error) {
      console.log("Extending Kits APi error", error.message);
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    getAllKits();
  }, []);
  return (
    <>
      {!pending ? (
        <>
          <DataTable
            columns={tableHead}
            data={_.sortBy(tableData, "total").reverse()}
            style={{
              borderRadius: "10px",
            }}
            pagination
            progressPending={pending}
          />
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Extending Kit</Modal.Title>
            </Modal.Header>
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={extendKits}
            >
              <Modal.Body className="text-center">
                You just need to enter the Quantity to be extended.
                <Col xl={12} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-dark">
                      <strong>Quantity</strong>
                    </Form.Label>
                    <Form.Control
                      name="quantity"
                      type="number"
                      placeholder="Quantity"
                      required
                      min={0}
                      max={50}
                    />
                  </Form.Group>
                </Col>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between align-items-center flex-column-reverse">
                <Button
                  className="w-100"
                  type="button"
                  variant="secondary"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button className="w-100" type="submit" variant="primary">
                  Extend
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </>
      ) : (
        <div className="loadingContainer text-center d-flex justify-content-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default ExtendExistingKitsHandler;
