import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import AdminLayout from "./../../../Layouts/AdminLayout";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { postRequestForm } from "../../../api";
import { useState } from "react";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import AdminImages from "../../../Constants/Admin/images";
import moment from "moment";

const CreateMedia = () => {
  const [images, setImages] = React.useState([AdminImages.LOGO]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [errors, setErrors] = useState();
  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setFeaturedImage(imageList[addUpdateIndex].file);
  };

  const addMediaHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (featuredImage) {
      formData.append("featuredImage", featuredImage);
    } else {
      setErrors(["Please select an Image"]);
    }
    if (!errors) {
      try {
        const token = localStorage.getItem("TOKEN");

        const response = await postRequestForm(
          "/api/secure/media/create-media",
          token,
          formData
        );

        if (response?.result?.status === 200) {
          toast.success("Media Created", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          window.location.assign("/allMedia");
        }
      } catch (error) {
        console.log("Create Media APi error", error.message);
      }
    }
  };
  return (
    <AdminLayout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          <Form
            className="form_add_user"
            encType="multipart/form-data"
            method="post"
            onSubmit={addMediaHandler}
          >
            <Row>
              <Col sm={12} className="text-center">
                <ImageUploading
                  value={images}
                  onChange={onChangeImage}
                  dataURLKey="data_url"
                  maxNumber={1}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    isDragging,
                    dragProps,
                  }) => (
                    <div className="upload__image-wrapper mx-auto mb-5">
                      <button
                        type="button"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {/* <i className="fal fa-plus"></i> */}
                      </button>
                      {imageList
                        ? imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image["data_url"]} alt="" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  type="button"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <FontAwesomeIcon icon={faPencilAlt} />
                                </button>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  )}
                </ImageUploading>
              </Col>
              {/* <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    required
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    name="middleName"
                    type="text"
                    placeholder="Middle Name"
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Suffix</Form.Label>
                  <Form.Control
                    name="suffix"
                    type="text"
                    placeholder="Suffix"
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="mail"
                    placeholder="Email"
                    required
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    name="phone"
                    type="tel"
                    placeholder="Phone"
                    required
                  />
                </Form.Group>
              </Col> */}
              {/* <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <ReactSelect
                    placeholder="Select Gender"
                    onChange={genderChange}
                    options={genderOptions}
                    required
                  />
                </Form.Group>
              </Col> */}
              {/* <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Date of Birth</Form.Label>
                  <DatePicker
                    selected={dateOfBirth}
                    onChange={(e) => {
                      setDateOfBirth(e);
                    }}
                    required
                    showYearDropdown
                    className="form-control"
                    maxDate={new Date()}
                    customInput={
                      <Form.Control type="text" placeholder="Date of Birth" />
                    }
                  />
                </Form.Group>
              </Col> */}
              {/* <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Race/Ethnicity</Form.Label>
                  <ReactSelect
                    placeholder="Select Race/Ethnicity"
                    onChange={raceChange}
                    options={raceOptions}
                    required={true}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Martial Status</Form.Label>
                  <ReactSelect
                    placeholder="Select Martial Status"
                    onChange={martialChange}
                    options={martialOptions}
                    required={true}
                  />
                </Form.Group>
              </Col> */}
              {/* <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    name="address"
                    type="text"
                    placeholder="Address"
                    required
                  />
                </Form.Group>
              </Col> */}
              {/* <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Consent to Electronic Communication</Form.Label>
                  <ReactSelect
                    placeholder="Select Electronic Communication"
                    onChange={eComunicationChange}
                    options={eComunicationOptions}
                    required
                  />
                </Form.Group>
              </Col> */}
              {/* <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Medical Doctor Address</Form.Label>
                  <Form.Control
                    name="medicalDoctorAddress"
                    type="text"
                    placeholder="Medical Doctor Address"
                    required
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Medical Doctor Phone</Form.Label>
                  <Form.Control
                    name="medicalDoctorNumber"
                    type="tel"
                    placeholder="Medical Doctor Phone"
                    required
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Insurance Information</Form.Label>
                  <ReactSelect
                    placeholder="Select Insurance Information"
                    onChange={insuranceChange}
                    options={insuranceOptions}
                    required
                  />
                </Form.Group>
              </Col>
              {insuranceInfo?.value == "yes" && insuranceInfo?.value ? (
                <>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        name="companyName"
                        type="text"
                        placeholder="Company Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Member Number</Form.Label>
                      <Form.Control
                        name="memberNumber"
                        type="text"
                        placeholder="Member Number"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Group Number</Form.Label>
                      <Form.Control
                        name="groupNumber"
                        type="text"
                        placeholder="Group Number"
                        required
                      />
                    </Form.Group>
                  </Col> */}

              {/* ) : (
                <></>
              )
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Password"
                    required
                  />
                </Form.Group>
              </Col> */}
              <Col xl={12} sm={12} className="text-center">
                {/* {errors
                  ? errors.map((error, index) => (
                      <div key={index} className="alert alert-danger">
                        {error}
                      </div>
                    ))
                  : ""} */}
                <Button type="submit">Submit</Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </AdminLayout>
  );
};

export default CreateMedia;
