import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import AdminLayout from "./../../../Layouts/AdminLayout";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { postRequestForm } from "../../../api";
import { useState } from "react";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import AdminImages from "../../../Constants/Admin/images";
import moment from "moment";
import PhoneInput from "react-phone-number-input";

const AddUsers = () => {
  const [images, setImages] = React.useState([AdminImages.LOGO]);
  const [profileImage, setProfileImage] = useState("");
  const [errors, setErrors] = useState();
  const [insuranceInfo, setInsuranceInfo] = useState();
  const [genderInfo, setGenderInfo] = useState();
  const [eComunication, setEComunicationInfo] = useState();
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [medicalDoctorAddress, setMedicalDoctorAddress] = useState("");
  const [medicalDoctorNumber, setMedicalDoctorNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [memberNumber, setMemberNumber] = useState("");
  const [groupNumber, setGroupNumber] = useState("");
  const [password, setPassword] = useState("");

  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setProfileImage(imageList[addUpdateIndex].file);
  };
  // eComunication
  const eComunicationOptions = [
    { value: "phone", label: "Phone" },
    { value: "email", label: "Email" },
    { value: "no", label: "No" },
  ];

  const eComunicationChange = (selectedOption) => {
    setEComunicationInfo(selectedOption);
    setErrors();
  };
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const genderChange = (selectedOption) => {
    setGenderInfo(selectedOption);
    setErrors();
  };
  const insuranceOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];

  const insuranceChange = (selectedOption) => {
    setInsuranceInfo(selectedOption);
    setErrors();
  };
  const addUserHandler = async (e) => {
    e.preventDefault();

    setErrors();
    const formData = new FormData();
    if (!insuranceInfo) {
      setErrors(["Please Select Insurance Info"]);
      return;
    }
    if (!genderInfo) {
      setErrors(["Please Select Gender"]);
      return;
    }
    if (!eComunication) {
      setErrors(["Please Select Consent to Electronic Communication"]);
      return;
    }
    if (!phone) {
      setErrors(["Please enter the Phone Number!"]);
      return;
    }

    if (profileImage) {
      formData.append("profileImage", profileImage);
    }
    formData.append("firstName", firstName);
    formData.append("middleName", middleName);
    formData.append("lastName", lastName);
    formData.append("suffix", suffix);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("medicalDoctorNumber", medicalDoctorNumber);
    formData.append("medicalDoctorAddress", medicalDoctorAddress);
    formData.append("password", password);
    formData.append("companyName", companyName);
    formData.append("memberNumber", memberNumber);
    formData.append("groupNumber", groupNumber);
    formData.append("electronicCommunication", eComunication?.value);
    formData.append("dob", moment(dateOfBirth).format("DD-MM-YYYY"));
    formData.append("insuranceInformation", insuranceInfo?.value);
    formData.append("gender", genderInfo?.value);

    if (!errors) {
      try {
        const response = await postRequestForm(
          "/api/auth/register",
          "",
          formData
        );

        if (response?.result?.status === 200) {
          toast.success("User Created", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          window.location.assign("/Users");
        } else if (response?.error?.response?.status === 400) {
          toast.error(`${response?.error?.response?.data?.message}`, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        console.log("Create Profile APi error", error.message);
      }
    }
  };
  return (
    <AdminLayout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          <Form
            className="form_add_user"
            encType="multipart/form-data"
            method="post"
            onSubmit={addUserHandler}
          >
            <Row>
              <Col sm={12} className="text-center">
                <ImageUploading
                  value={images}
                  onChange={onChangeImage}
                  dataURLKey="data_url"
                  maxNumber={1}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    isDragging,
                    dragProps,
                  }) => (
                    <div className="upload__image-wrapper mx-auto mb-5">
                      <button
                        type="button"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {/* <i className="fal fa-plus"></i> */}
                      </button>
                      {imageList
                        ? imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image["data_url"]} alt="" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  type="button"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <FontAwesomeIcon icon={faPencilAlt} />
                                </button>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  )}
                </ImageUploading>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    name="middleName"
                    type="text"
                    placeholder="Middle Name"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12} className="d-none">
                <Form.Group className="mb-3">
                  <Form.Label>Suffix</Form.Label>
                  <Form.Control
                    name="suffix"
                    type="text"
                    placeholder="Suffix"
                    value={suffix}
                    onChange={(e) => setSuffix(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="mail"
                    placeholder="Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <PhoneInput
                      international
                    placeholder="Enter phone number"
                    value={phone}
                    defaultCountry="US"
                    onChange={setPhone}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <ReactSelect
                    placeholder="Select Gender"
                    onChange={genderChange}
                    options={genderOptions}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Date of Birth</Form.Label>
                  <DatePicker
                    selected={dateOfBirth}
                    onChange={(e) => {
                      setDateOfBirth(e);
                    }}
                    required
                    showYearDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    maxDate={new Date()}
                    customInput={
                      <Form.Control type="text" placeholder="Date of Birth" />
                    }
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    name="address"
                    type="text"
                    placeholder="Address"
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Consent to Electronic Communication</Form.Label>
                  <ReactSelect
                    placeholder="Select Electronic Communication"
                    onChange={eComunicationChange}
                    options={eComunicationOptions}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Medical Doctor Name</Form.Label>
                  <Form.Control
                    name="medicalDoctorAddress"
                    type="text"
                    placeholder="Medical Doctor Name"
                    value={medicalDoctorAddress}
                    onChange={(e) => setMedicalDoctorAddress(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Medical Doctor Phone</Form.Label>
                  <Form.Control
                    name="medicalDoctorNumber"
                    type="tel"
                    placeholder="Medical Doctor Phone"
                    value={medicalDoctorNumber}
                    onChange={(e) => setMedicalDoctorNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Insurance Information</Form.Label>
                  <ReactSelect
                    placeholder="Select Insurance Information"
                    onChange={insuranceChange}
                    options={insuranceOptions}
                    required
                  />
                </Form.Group>
              </Col>
              {insuranceInfo?.value == "yes" && insuranceInfo?.value ? (
                <>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        name="companyName"
                        type="text"
                        placeholder="Company Name"
                        required
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Member Number</Form.Label>
                      <Form.Control
                        name="memberNumber"
                        type="text"
                        placeholder="Member Number"
                        required
                        value={memberNumber}
                        onChange={(e) => setMemberNumber(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Group Number</Form.Label>
                      <Form.Control
                        name="groupNumber"
                        type="text"
                        placeholder="Group Number"
                        required
                        value={groupNumber}
                        onChange={(e) => setGroupNumber(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </>
              ) : (
                <></>
              )}
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl={12} sm={12} className="text-center">
                {errors
                  ? errors.map((error, index) => (
                      <div key={index} className="alert alert-danger">
                        {error}
                      </div>
                    ))
                  : ""}
                <Button type="submit">Submit</Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </AdminLayout>
  );
};

export default AddUsers;
