import React, { useEffect } from "react";
import AdminLayout from "./../../../Layouts/AdminLayout";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { getRequest, putRequest } from "../../../api";
import { useState } from "react";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import AdminImages from "../../../Constants/Admin/images";
import { ThreeDots } from "react-loader-spinner";
import { uploadURL } from "./../../../api";
import moment from "moment/moment";

const EditMedia = () => {
  let userId = "";
  const location = useLocation();
  const history = useHistory();
  if (location?.state?.userId === "") {
    history.push("/Users");
  } else {
    userId = location?.state?.userId;
  }
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [images, setImages] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [insuranceInfo, setInsuranceInfo] = useState();
  // const [martialInfo, setMartialInfo] = useState();
  // const [raceInfo, setRaceInfo] = useState();
  const [genderInfo, setGenderInfo] = useState();
  const [eComunication, setEComunicationInfo] = useState();
  const [dateOfBirth, setDateOfBirth] = useState(new Date());

  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setProfileImage(imageList[addUpdateIndex].file);
  };
  // eComunication
  const eComunicationOptions = [
    { value: "phone", label: "Phone" },
    { value: "email", label: "Email" },
    { value: "no", label: "No" },
  ];

  const eComunicationChange = (selectedOption) => {
    setEComunicationInfo(selectedOption);
  };
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const genderChange = (selectedOption) => {
    setGenderInfo(selectedOption);
  };
  // const raceOptions = [
  //   { value: "race", label: "Race" },
  //   { value: "ethnicity", label: "Ethnicity" },
  // ];

  // const raceChange = (selectedOption) => {
  //   setRaceInfo(selectedOption);
  // };
  const insuranceOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];

  const insuranceChange = (selectedOption) => {
    setInsuranceInfo(selectedOption);
  };
  // const martialOptions = [
  //   { value: "married", label: "Married" },
  //   { value: "divorced", label: "Divorced" },
  //   { value: "single", label: "Single" },
  // ];

  // const martialChange = (selectedOption) => {
  //   setMartialInfo(selectedOption);
  // };
  const updateUserHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    console.log("e.target", e);
    formData.append("firstName", e.target[3].value);
    formData.append("middleName", e.target[4].value);
    formData.append("lastName", e.target[5].value);
    formData.append("suffix", e.target[6].value);
    formData.append("email", e.target[7].value);
    formData.append("phone", e.target[8].value);
    if (e.target[16].value != "" && insuranceInfo.value == "no") {
      formData.append("password", e.target[16].value);
    } else {
      if (e.target[16].value != "" && insuranceInfo.value == "") {
        formData.append("password", e.target[16].value);
      } else {
        if (insuranceInfo.value == "yes") {
          formData.append("companyName", e.target[16].value);
          formData.append("memberNumber", e.target[17].value);
          formData.append("groupNumber", e.target[18].value);
          if(e.target[19].value){
            formData.append("password", e.target[19].value);
          }
        }
      }
    }
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }
    formData.append("dob", moment(dateOfBirth.value).format("DD-MM-YYYY"));
    formData.append(
      "insuranceInformation",
      insuranceInfo.value ? insuranceInfo.value : ""
    );
    // formData.append("maritalStatus", martialInfo.value);
    // formData.append("raceEthnicity", raceInfo.value);
    formData.append("gender", genderInfo.value ? genderInfo.value : "");
    formData.append(
      "electronicCommunication",
      eComunication.value ? eComunication.value : ""
    );
    formData.append("_id", userId);
    console.log("formData", Object.fromEntries(formData));
    // try {
    //   const response = await putRequest(
    //     "/api/secure/user/edit-profile",
    //     "",
    //     formData
    //   );

    //   // console.log("status", response);
    //   if (response?.result?.status === 200) {
    //     toast.success("User Updated", {
    //       position: "bottom-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: false,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     // window.location.assign("/Users");
    //   }
    // } catch (error) {
    //   console.log("Create Profile APi error", error.message);
    // }
  };
  const getUserByID = async (userId) => {
    try {
      const response = await getRequest("/api/secure/user/profileById", "", {
        _id: userId,
      });

      if (response?.result?.status === 200) {
        console.log("user", response?.result?.data?.user);
        setUser(response?.result?.data?.user);
        // console.log("user", user);
        console.log("user", response?.result?.data?.user?.profileImage);
        setImages([
          {
            data_url: uploadURL + response?.result?.data?.user?.profileImage,
          },
        ]);
        // setMartialInfo({ value: response?.result?.data?.user.maritalStatus });
        setEComunicationInfo({
          value: response?.result?.data?.user.electronicCommunication,
        });
        setGenderInfo({ value: response?.result?.data?.user.gender });
        // setRaceInfo({ value: response?.result?.data?.user.raceEthnicity });
        setInsuranceInfo({
          value: response?.result?.data?.user.insuranceInformation,
        });
        setDateOfBirth({
          value: new Date(
            moment(response?.result?.data?.user.dob).format("DD-MM-YYYY")
          ),
        });

        console.log(
          "response?.result?.data?.user.dob",
          response?.result?.data?.user.dob
        );
        setLoading(false);
      }
    } catch (error) {
      console.log("Get Profile APi error", error.message);
    }
  };
  useEffect(() => {
    getUserByID(userId);
  }, [userId, location, history]);

  return (
    <AdminLayout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updateUserHandler}
            >
              <Row className="bg-white py-3 m-0">
                {/* <> */}
                  <Col sm={12} className="text-center">
                    <ImageUploading
                      value={images}
                      onChange={onChangeImage}
                      dataURLKey="data_url"
                      maxNumber={1}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        isDragging,
                        dragProps,
                      }) => (
                        <div className="upload__image-wrapper mx-auto mb-5">
                          <button
                            type="button"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            {/* <i className="fal fa-plus"></i> */}
                          </button>
                          {imageList
                            ? imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img src={image["data_url"]} alt="" />
                                  <div className="image-item__btn-wrapper">
                                    <button
                                      type="button"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <FontAwesomeIcon icon={faPencilAlt} />
                                    </button>
                                  </div>
                                </div>
                              ))
                            : ""}
                        </div>
                      )}
                    </ImageUploading>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        required
                        defaultValue={user.firstName}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        name="middleName"
                        type="text"
                        placeholder="Middle Name"
                        defaultValue={user.middleName}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        defaultValue={user.lastName}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Suffix</Form.Label>
                      <Form.Control
                        name="suffix"
                        type="text"
                        placeholder="Suffix"
                        defaultValue={user.suffix}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name="email"
                        type="mail"
                        placeholder="Email"
                        required
                        defaultValue={user.email}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        name="phone"
                        type="tel"
                        placeholder="Phone"
                        required
                        defaultValue={user.phone}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Gender</Form.Label>
                      <ReactSelect
                        placeholder="Select Gender"
                        onChange={genderChange}
                        options={genderOptions}
                        required
                        defaultValue={{ value: true, label: user.gender }}
                        // defaultValue={user.gender}
                      />
                    </Form.Group>
                  </Col> */}
                  {/* <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Date of Birth</Form.Label>
                      {console.log(user.dob)}
                      {console.log(
                        new Date(moment(user.dob).format("YYYY-MM-DD"))
                      )}

                      <DatePicker
                        selected={dateOfBirth.value}
                        onChange={(e) => {
                          setDateOfBirth({
                            value: new Date(moment(e).format("YYYY-MM-DD")),
                          });
                        }}
                        required
                        showYearDropdown
                        className="form-control"
                        maxDate={new Date()}
                        customInput={
                          <Form.Control
                            type="text"
                            placeholder="Date of Birth"
                          />
                        }
                      />
                    </Form.Group>
                  </Col> */}
                  {/* <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Race/Ethnicity</Form.Label>
                      <ReactSelect
                        placeholder="Select Race/Ethnicity"
                        onChange={raceChange}
                        options={raceOptions}
                        required
                        defaultValue={{
                          value: true,
                          label: user.raceEthnicity,
                        }}
                        // defaultValue={user.gender}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Martial Status</Form.Label>
                      <ReactSelect
                        placeholder="Select Martial Status"
                        onChange={martialChange}
                        options={martialOptions}
                        required
                        defaultValue={{
                          value: user.maritalStatus,
                          label:
                            user.maritalStatus == "single"
                              ? "Single"
                              : user.maritalStatus == "married"
                              ? "Married"
                              : user.maritalStatus == "divorced"
                              ? "Divorced"
                              : "",
                        }}
                      />
                    </Form.Group>
                  </Col> */}
                  {/* <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        name="address"
                        type="text"
                        placeholder="Address"
                        // required
                        defaultValue={user.address}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Consent to Electronic Communication
                      </Form.Label>
                      <ReactSelect
                        placeholder="Select Electronic Communication"
                        onChange={eComunicationChange}
                        options={eComunicationOptions}
                        defaultValue={{
                          value: true,
                          label: user.electronicCommunication,
                        }}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Medical Doctor Address</Form.Label>
                      <Form.Control
                        name="medicalDoctorAddress"
                        type="text"
                        placeholder="Medical Doctor Address"
                        // required
                        defaultValue={user.medicalDoctorAddress}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Medical Doctor Phone</Form.Label>
                      <Form.Control
                        name="medicalDoctorNumber"
                        type="tel"
                        placeholder="Medical Doctor Phone"
                        // required
                        defaultValue={user.medicalDoctorNumber}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Insurance Information</Form.Label>
                      <ReactSelect
                        placeholder="Select Insurance Information"
                        onChange={insuranceChange}
                        options={insuranceOptions}
                        defaultValue={{
                          value: true,
                          label: user.insuranceInformation,
                        }}
                        required
                      />
                    </Form.Group>
                  </Col>
                  {insuranceInfo?.value == "yes" && insuranceInfo?.value ? (
                    <> */}
                      {/* <Col xl={4} sm={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control
                            name="companyName"
                            type="text"
                            placeholder="Company Name"
                            required
                            defaultValue={user?.companyName}
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={4} sm={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Member Number</Form.Label>
                          <Form.Control
                            name="memberNumber"
                            type="text"
                            placeholder="Member Number"
                            required
                            defaultValue={user?.memberNumber}
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={4} sm={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Group Number</Form.Label>
                          <Form.Control
                            name="groupNumber"
                            type="text"
                            placeholder="Group Number"
                            required
                            defaultValue={user?.groupNumber}
                          />
                        </Form.Group>
                      </Col> */}
                    {/* </>
                  ) : (
                    <></> */}
                  {/* )} */}
                  {/* <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        name="password"
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Group>
                  </Col> */}
                {/* </> */}

                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default EditMedia;
