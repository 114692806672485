import React, { useEffect } from "react";
import AdminLayout from "./../../../Layouts/AdminLayout";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { getRequest, putRequest } from "../../../api";
import { useState } from "react";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ThreeDots } from "react-loader-spinner";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import ColorShadesTintsPicker from "./../../../Components/Admin/ColorShadesTintsPicker";
import ReactSelect from "react-select";
import TimePicker from "react-time-picker";
import moment from "moment";
const UpdateKits = () => {
  let groupId = "";
  let manufacturingDate = "";
  const location = useLocation();
  const history = useHistory();
  if (location?.state?.groupId === "") {
    history.push("/updateKitsByGroup");
  } else {
    groupId = location?.state?.groupId;
  }
  if (location?.state?.manufacturingDate === "") {
    history.push("/updateKitsByGroup");
  } else {
    manufacturingDate = location?.state?.manufacturingDate;
  }
  const [isLoading, setLoading] = useState(true);
  const [Group, setGroup] = useState();
  const [Kit, setKit] = useState();
  const [colors, setColors] = useState([]);
  const [editor, setEditor] = useState();
  const [stepSaver, setStepSaver] = useState([]);
  const [steps, setSteps] = useState([]);
  const [testTime, setTestTime] = useState();
  const [age, setAge] = useState();

  const ageOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];
  const ageOptionChange = (selectedOption) => {
    setAge(selectedOption);
  };

  const onChangeEditorHandle = (editorState) => {
    setEditor(editorState);
  };
  const addRowTable = () => {
    const addStep = {
      content: "",
    };
    setSteps([...steps, addStep]);
    const addStepSaver = {
      content: "",
    };
    setStepSaver([...stepSaver, addStepSaver]);
  };
  const removeRowTable = (index) => {
    const addStepSaverRow = [...stepSaver];
    addStepSaverRow.splice(index, 1);
    setStepSaver(addStepSaverRow);
    const stepRow = [...steps];
    stepRow.splice(index, 1);
    setSteps(stepRow);
  };
  const convertStepsContentToEditorState = async (steps) => {
    var finalSteps = [];
    var finalStepsSaver = [];
    steps.map((step, index) => {
      const contentBlock = htmlToDraft(step.content);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        finalSteps.push({
          _id: step._id,
          content: editorState,
        });
        finalStepsSaver.push({
          _id: step._id,
          content: step.content,
        });
      }
    });
    setSteps(finalSteps);
    setStepSaver(finalStepsSaver);
  };
  const addRowTableForColors = () => {
    const addColor = {
      baseColor: "",
      colorResultCase: "",
    };
    setColors([...colors, addColor]);
  };
  const removeRowTableForColors = (rowIndex) => {
    let colorRows = [...colors];
    colorRows.splice(rowIndex, 1);
    setColors(colorRows);
  };
  const onChangeResultColorHandler = (index, baseColor) => {
    const color = [...colors];
    color[index].baseColor = baseColor;
    setColors(color);
  };
  const colorCaseOptions = [
    { value: "pass", label: "Pass" },
    { value: "fail", label: "Fail" },
    { value: "partial_pass", label: "Partially Pass" },
    { value: "partial_fail", label: "Partially Fail" },
  ];
  const colorCaseChange = (selectedOption, index) => {
    const color = [...colors];
    color[index].colorResultCase = selectedOption.value;
    setColors(color);
  };
  const onChangeStepEditorHandle = (index, editorState) => {
    const step = [...steps];
    step[index].content = editorState;
    setSteps(step);
    const stepSavers = [...stepSaver];
    stepSavers[index].content = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setStepSaver(stepSavers);
  };
  const getKitGroupByID = async (groupId) => {
    try {
      const token = localStorage.getItem("TOKEN");

      const response = await getRequest("/api/secure/kit/kit-group", token, {
        _id: groupId,
      });
      if (response?.result?.status === 200) {
        setGroup(response?.result?.data?.kitGroup);
        const kitResponse = await getRequest(
          "/api/secure/kit/get-kit-by-custom-query",
          token,
          {
            groupId: response?.result?.data?.kitGroup._id,
            manufacturingDate,
          }
        );
        setKit(kitResponse.result.data.kits.reverse()[0]);
        const contentBlock = htmlToDraft(
          kitResponse.result.data.kits.reverse()[0].description
        );
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        }
        setSteps(kitResponse.result.data.kits.reverse()[0].steps);
        convertStepsContentToEditorState(
          kitResponse.result.data.kits.reverse()[0].steps
        );
        setColors(kitResponse.result.data.kits.reverse()[0].resultCodes);
        setTestTime(kitResponse.result.data.kits.reverse()[0].testTime);
        setAge(kitResponse.result.data.kits.reverse()[0].ageLimit);
        setLoading(false);
      }
    } catch (error) {
      console.log("Get Profile APi error", error.message);
    }
  };
  const updateKitsHandler = async (e) => {
    e.preventDefault();
    console.log({
      name: e.target[0].value,
      description: draftToHtml(convertToRaw(editor.getCurrentContent())),
      internalNumber: e.target[3].value,
      testTime: testTime,
      steps: stepSaver,
      resultCodes: colors,
      ageLimit: age,
      manufacturingDateOld: manufacturingDate,
      manufacturingDate: moment(e.target[1].value).format("DD-MM-YYYY"),
      expire: moment(e.target[2].value).format("DD-MM-YYYY"),
    });
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await putRequest("/api/secure/kit/update-kits", token, {
        name: e.target[0].value,
        description: draftToHtml(convertToRaw(editor.getCurrentContent())),
        internalNumber: e.target[3].value,
        testTime: testTime,
        steps: stepSaver,
        resultCodes: colors,
        ageLimit: age,
        manufacturingDateOld: manufacturingDate,
        manufacturingDate: e.target[1].value,
        expire: e.target[2].value,
      });

      // console.log("status", response);
      if (response?.result?.status === 200) {
        toast.success("Kits has been Updated", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        history.push({
          pathname: "/updateKitsByGroup",
          state: { _id: groupId },
        });
      }
    } catch (error) {
      console.log("Update Group APi error", error.message);
    }
  };
  useEffect(() => {
    getKitGroupByID(groupId);
  }, [groupId]);

  return (
    <AdminLayout>
      <section id="add_kit_group_form_section" className="py-5 ">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updateKitsHandler}
            >
              <Row className="bg-white py-3 m-0">
                {Kit ? (
                  <>
                    <Col xl={4} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-dark">
                          <strong>Name</strong>
                        </Form.Label>
                        <Form.Control
                          name="name"
                          type="text"
                          placeholder="Name"
                          required
                          defaultValue={Kit.name}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-dark">
                          <strong>Manufacturing Date</strong>
                        </Form.Label>
                        <Form.Control
                          name="manufacturingDate"
                          type="date"
                          placeholder={
                            Kit.manufacturingDate
                              ? moment(Kit.manufacturingDate).format(
                                  "YYYY-MM-DD"
                                )
                              : "YYYY-MM-DD"
                          }
                          required
                          value={moment(Kit.manufacturingDate).format(
                            "YYYY-MM-DD"
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-dark">
                          <strong>Expire Date</strong>
                        </Form.Label>
                        <Form.Control
                          name="expireDate"
                          type="date"
                          required
                          placeholder={
                            Kit.expire
                              ? moment(Kit.expire).format("YYYY-MM-DD")
                              : "YYYY-MM-DD"
                          }
                          value={moment(Kit.expire).format("YYYY-MM-DD")}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-dark">
                          <strong>Test Timer Duration (minutes:seconds)</strong>
                        </Form.Label>
                        <TimePicker
                          disableClock
                          required
                          format={"m:s"}
                          onChange={setTestTime}
                          value={testTime}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Age Limit 18+ ( Yes / No )</Form.Label>
                        <ReactSelect
                          placeholder="Is 18+?"
                          onChange={ageOptionChange}
                          options={ageOptions}
                          required
                          defaultValue={{
                            value: age,
                            label:
                              age == "no" ? "No" : age == "yes" ? "Yes" : "",
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={12} className="bg-light py-3">
                      <Form.Label className="text-dark">
                        <strong>Description</strong>
                      </Form.Label>
                      <Editor
                        editorState={editor}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        onEditorStateChange={onChangeEditorHandle}
                      />
                    </Col>
                    <Col sm={12} className="py-3">
                      <Form.Label className="text-dark">
                        <strong>Kit Step</strong>
                      </Form.Label>
                      <Table
                        id="kitStepTable"
                        responsive
                        striped
                        bordered
                        className="dynamic_table"
                      >
                        <tbody>
                          {steps ? (
                            steps.map((rowsData, index) => (
                              <tr key={index}>
                                <td>
                                  <Table className="bg-white m-0">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <Form.Label>
                                            Setp {index + 1} Content
                                          </Form.Label>
                                          <Editor
                                            editorState={rowsData.content}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            onEditorStateChange={(
                                              editorState
                                            ) => {
                                              onChangeStepEditorHandle(
                                                index,
                                                editorState
                                              );
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                                <td>
                                  <Button
                                    className="btn-danger"
                                    onClick={() => removeRowTable(index)}
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </Button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={2}>
                              <div className="d-flex justify-content-between  align-items-center">
                                <p className="m-0">Create New Step</p>
                                <Button
                                  className="btn-primary"
                                  onClick={() => addRowTable(steps)}
                                >
                                  <FontAwesomeIcon icon={faAdd} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </Col>
                    <Col sm={12} className="py-3">
                      <Form.Label className="text-dark">
                        <strong>Result Colors</strong>
                      </Form.Label>
                      <Table
                        id="kitResultColorTable"
                        responsive
                        striped
                        bordered
                        className="dynamic_table"
                      >
                        <tbody>
                          {colors ? (
                            colors.map((rowsData, index) => (
                              <tr key={index}>
                                <td>
                                  <Table className="bg-white m-0">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <Form.Label>
                                            Color {index + 1}
                                          </Form.Label>
                                          <ColorShadesTintsPicker
                                            value={colors[index]}
                                            onChangeColor={(color) => {
                                              onChangeResultColorHandler(
                                                index,
                                                color
                                              );
                                            }}
                                          />
                                          <Form.Label>
                                            Color {index + 1} Case
                                          </Form.Label>
                                          <ReactSelect
                                            placeholder="Select Gender"
                                            onChange={(selectedOption) => {
                                              colorCaseChange(
                                                selectedOption,
                                                index
                                              );
                                            }}
                                            options={colorCaseOptions}
                                            required
                                            defaultValue={{
                                              value:
                                                colors[index].colorResultCase,
                                              label:
                                                colors[index].colorResultCase ==
                                                "pass"
                                                  ? "Pass"
                                                  : colors[index]
                                                      .colorResultCase == "fail"
                                                  ? "Fail"
                                                  : colors[index]
                                                      .colorResultCase ==
                                                    "partial_pass"
                                                  ? "Partially Pass"
                                                  : colors[index]
                                                      .colorResultCase ==
                                                    "partial_fail"
                                                  ? "Partially Fail"
                                                  : "",
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                                <td>
                                  <Button
                                    className="btn-danger"
                                    onClick={() =>
                                      removeRowTableForColors(index)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </Button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={2}>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="m-0">Create New Color Case</p>
                                <Button
                                  className="btn-primary"
                                  onClick={() => addRowTableForColors(steps)}
                                >
                                  <FontAwesomeIcon icon={faAdd} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </Col>
                    <Col sm={12} className="py-3">
                      {/* <ColorShadesTintsPicker /> */}
                    </Col>
                  </>
                ) : (
                  ""
                )}

                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Update Kits</Button>
                </Col>
                {/* <Col xl={4} sm={12} className="text-center">
                    <Link
                      to={{
                        pathname: `/updateKitsByGroup`,
                        state: { _id: groupId },
                      }}
                      className="btn btn_primary"
                    >
                      Update Kits
                    </Link>
                  </Col> */}
              </Row>
            </Form>
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default UpdateKits;
