import LOGO from "../../Assets/img/logo.png";
import dashboard from "../../Assets/img/dashboard.png";
import settings from "../../Assets/img/setting.png";
import logout from "../../Assets/img/logout.png";
import toggler from "../../Assets/img/toggler.png";
export default {
  LOGO,
  dashboard,
settings,
logout,
toggler
}