import React, { useState } from "react";
import AuthLayout from "../../Layouts/AuthLayout";
import { useHistory, useLocation } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import { postRequestForm } from "./../../api";

const OTP = () => {
  let { _id, route } = "";
  const location = useLocation();
  const history = useHistory();
  const [OTP, setOTP] = useState("");
  const [OTPTime, setOTPTime] = useState(60);
  const [OTPMsg, setOTPMsg] = useState("");

  if (location?.state?._id === "") {
    history.push("/login");
  } else {
    _id = location?.state?._id;
  }
  if (location?.state?.pathToGo === "") {
    route = "dashboard";
  } else {
    route = location?.state?.pathToGo;
  }
  const renderButton = (buttonProps) => {
    return <button type="button" {...buttonProps}>Retry</button>;
  };
  const renderTime = (time) => {
    return <span className="text-dark">Please wait for {time}s </span>;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postRequestForm(`/api/auth/verifyOTP`, "", {
        _id: _id,
        otp: OTP,
      });
      if (res?.result?.status === 200) {
        const response = res.result.data;
        const user = response?.user;
        const token = response?.token;
        if (route === "dashboard") {
          localStorage.setItem("TOKEN", token);
          localStorage.setItem("ROLE", user.role);
          localStorage.setItem("USER", JSON.stringify(user, null, 2));
          history.push({
            pathname: `/${route}`,
          });
        } else {
          history.push({
            pathname: `/${route}`,
            state: { _id: user._id },
          });
        }
      } else if (res.error.response.status === 400) {
        alert("OTP has been expired!");
        history.push("/login");
      }
    } catch (err) {
      console.log(`Error of login`, err.message);
    }
  };
  const resendOTP = async () => {
    setOTPTime(60);
    try {
      const res = await postRequestForm(`/api/auth/resendOTP`, "", {
        _id: _id,
      });
      if (res?.result?.status === 200) {
        setOTPMsg(
          "OTP has been sent to you email address. Please make sure to check spam folder."
        );
        setTimeout(() => {
          setOTPMsg('')
        }, 3000);
      }
    } catch (err) {
      console.log(`Error of Sending OTP again`, err.message);
    }
  };
  return (
    <AuthLayout>
      <section className=" pb-2">
        <div
          className="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
          style={{
            background: "linear-gradient(179deg, #15bd98 36.03%, #0fabd6 104.15%)"

          }}
        >
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 text-center mx-auto">
                <h1 className="text-white mb-2 mt-5">&nbsp;</h1>
                <p className="text-lead text-white">
                  Please enter your OTP to Login into your Account
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-lg-n10 mt-md-n11 mt-n10">
            <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
              <div className="card z-index-0">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={6}
                      disabled={false}
                      otpType={"number"}
                    />
                    <ResendOTP
                      maxTime={OTPTime}
                      renderButton={renderButton}
                      renderTime={renderTime}
                      onResendClick={resendOTP}
                    />
                    {OTPMsg ? (
                      <div className="my-3">
                        <div className="alert alert-success" role="alert">
                          {OTPMsg}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn w-100"
                        >
                        Verify
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AuthLayout>
  );
};

export default OTP;
