import React, { useState, useEffect } from "react";
import AdminLayout from "./../../../Layouts/AdminLayout";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { getRequest, putRequest } from "./../../../api";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMinus } from "@fortawesome/free-solid-svg-icons";
const Faqs = () => {
  const [isLoading, setLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);
  const [page, setPage] = useState([]);
  const updatePageHandler = async (e) => {
    e.preventDefault();
    console.log("faqs", faqs);
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await putRequest("/api/secure/page/update-faqs", token, {
        pageName: "FAQs",
        questions: faqs,
      });
      // console.log("status", response);
      if (response?.result?.status === 200) {
        toast.success("FAQs Page Updated", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        // window.location.assign("/Users");
      }
    } catch (error) {
      console.log("Update Page APi error", error.message);
    }
  };
  const getPageByName = async (pageName) => {
    try {
      const response = await getRequest("/api/secure/page/faqs", "", {
        pageName,
      });
      if (response?.result?.status === 200) {
        setPage(response?.result?.data?.faqsPage);
        setFaqs(response?.result?.data?.faqsPage?.questions);
        setLoading(false);
      }
    } catch (error) {
      console.log("Get Page APi error", error.message);
    }
  };
  const addRowTable = () => {
    const addFaq = {
      question: "",
      answer: "",
    };
    setFaqs([...faqs, addFaq]);
  };
  const removeRowTable = (index) => {
    const faqRow = [...faqs];
    faqRow.splice(index, 1);
    setFaqs(faqRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const faq = [...faqs];
    faq[i][name] = value;
    setFaqs(faq);
  };
  useEffect(() => {
    getPageByName("FAQs");
  }, []);

  return (
    <AdminLayout>
      <section className="py-5">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updatePageHandler}
            >
              <Row>
                <Col sm={12}>
                  <Form.Label>
                    <b>FAQs Content</b>
                  </Form.Label>
                  <Table id="faqsTable" responsive striped>
                    <tbody>
                      {faqs.map((rowsData, index) => (
                        <tr key={index}>
                          <td>
                            <Table className="bg-white m-0">
                              <tbody>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Question</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="question"
                                      defaultValue={rowsData.question}
                                      onChange={(event) =>
                                        onValUpdate(index, event)
                                      }
                                      required
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Answer</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="answer"
                                      defaultValue={rowsData.answer}
                                      onChange={(event) =>
                                        onValUpdate(index, event)
                                      }
                                      required
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                          <td>
                            <Button
                              className="btn-danger"
                              onClick={() => removeRowTable(index)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="text-end" colSpan={2}>
                          <Button className="btn-primary" onClick={addRowTable}>
                            <FontAwesomeIcon icon={faAdd} />
                          </Button>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
                <Col xl={12} sm={12} className="text-center mt-4">
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default Faqs;
