import React from "react";
import { Button, Container } from "react-bootstrap";
import AdminLayout from "./../../../Layouts/AdminLayout";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm, uploadURL } from "../../../api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useHistory } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const AllMedia = () => {
  const history = useHistory();
  const [tableHead, setTableHead] = useState();
  const [columns, setColumns] = useState([]);
  const [pending, setPending] = React.useState(true);
  const [tableData, setTableData] = useState();

  const getAllMedia = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      // console.log("token", token);
      // var params = props.location.search.slice(5);
      const response = await getRequest(
        `/api/secure/media/get-all-media`,
        token
      );

      setTableData(response.result.data.media);
      setTableHead([
        {
          name: "File Name",
          // sortable: true,
          selector: (row) => row.featuredImage,
        },
        {
          name: "Date",
          // sortable: true,
          selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          // minWidth: "50px",
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() =>
                  copySingleMedia(row.featuredImage, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
              <Button
                className="del_btn ms-1"
                size="sm"
                variant="danger"
                onClick={() =>
                  deleteSingleMedia(row._id, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);
      setPending(false);
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };
  const copyToClipboard = (clipboardText) => {
    // Create a temporary input element
    const tempInput = document.createElement("input");
    // Set the input value to the clipboardText
    tempInput.value = clipboardText;
    // Append the input element to the document (but keep it hidden)
    tempInput.style.position = "absolute";
    tempInput.style.left = "-9999px";
    document.body.appendChild(tempInput);
    // Select the input content
    tempInput.select();
    // Copy the selected content to clipboard
    document.execCommand("copy");
    // Remove the temporary input element
    document.body.removeChild(tempInput);
    console.log("Image URL copied to clipboard!",clipboardText);
  };
  const copySingleMedia = async (featuredImage, row, index, column, id) => {
    try {
      copyToClipboard(uploadURL + featuredImage);

      console.log("Image copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy image:", error);
    }
  };
  const deleteSingleMedia = async (mediaId, row, index, column, id) => {
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await postRequestForm(
        `/api/secure/media/delete-media`,
        token,
        { mediaId }
      );
      // console.log(response);
      if (response.result.status === 200) {
        toast.success("Media Deleted", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        getAllMedia();
      }
    } catch (error) {
      console.log("Get Site Setting Error", error);
    }
  };

  useEffect(() => {
    getAllMedia();
  }, []);

  return (
    <AdminLayout>
      <section id="users_list" className="pt-5">
        <Container>
          {!pending ? (
            <DataTable
              columns={tableHead}
              data={_.sortBy(tableData, "total").reverse()}
              style={{
                borderRadius: "10px",
              }}
              pagination
              progressPending={pending}
            />
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default AllMedia;
