import React from "react";
import { Container } from "react-bootstrap";
import AdminLayout from "./../../Layouts/AdminLayout";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useHistory } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import { ThreeDots } from "react-loader-spinner";

const Dashboard = () => {
  const [isLoading, setLoading] = useState(true);
  const [kitLength, setKitLength] = useState(0);
  const [activeKitsLength, setActiveKitsLength] = useState(0);
  const [usersLength, setUsersLength] = useState(0);
  const [resultsLength, setResultsLength] = useState(0);

  const getAllKits = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      const kitResponse = await getRequest(`/api/secure/kit/get-all`, token);
      let activeKits = [];
      setKitLength(kitResponse?.result?.data?.kits?.length);

      setLoading(false);
    } catch (error) {
      console.log("Get All Kits Error", error);
    }
  };
  const getAllKitsActivated = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      const kitResponse = await getRequest(
        `/api/secure/kit/get-all-activated-kit`,
        token
      );
      setActiveKitsLength(kitResponse?.result?.data?.kits?.length);

      setLoading(false);
    } catch (error) {
      console.log("Get All Activated Kits Error", error);
    }
  };
  const getAllUsers = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await getRequest(`/api/secure/user/get-all`, token);
      setUsersLength(response?.result?.data?.users?.length);

      setLoading(false);
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };

  const getAllResults = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await getRequest(
        `/api/secure/result/get-results`,
        token
      );
      setResultsLength(response?.result?.data?.results?.length);

      setLoading(false);
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };

  useEffect(() => {
    getAllKits();
    getAllKitsActivated()
    getAllUsers();
    getAllResults();
  }, []);

  return (
    <AdminLayout>
      <section className="dashboard">
        <Container>
          {!isLoading ? (
            <div className="card-list">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card blue">
                    <div className="title">kits</div>
                    <i className="zmdi zmdi-upload"></i>
                    <div className="value">{kitLength}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card green">
                    <div className="title">activated kits</div>
                    <i className="zmdi zmdi-upload"></i>
                    <div className="value">{activeKitsLength}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card orange">
                    <div className="title">users</div>
                    <i className="zmdi zmdi-download"></i>
                    <div className="value">{usersLength}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card red">
                    <div className="title">results</div>
                    <i className="zmdi zmdi-download"></i>
                    <div className="value">{resultsLength}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}

          {/* ------------------------- */}
          {/* ----------------------------- */}

          {/* <div className="projects mb-4">
            <div className="projects-inner">
              <header className="projects-header">
                <div className="title">Medical Kits</div>
                <div className="count">| 32 Kits</div>
                <i className="zmdi zmdi-download"></i>
              </header>
              <table className="projects-table">
                <thead>
                  <tr>
                    <th>Kit</th>
                    <th>Date</th>
                    <th>User</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>New Dashboard</p>
                    </td>
                    <td>
                      <p>17th Oct, 15</p>
                    </td>
                    <td className="member">
                      <figure>
                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/584938/people_8.png" />
                      </figure>
                      <div className="member-info">
                        <p>Myrtle Erickson</p>
                      </div>
                    </td>
                    <td className="status">
                      <span className="status-text status-orange">
                        In progress
                      </span>
                    </td>
                  </tr>
                  <tr className="danger-item">
                    <td>
                      <p>New Dashboard</p>
                    </td>
                    <td>
                      <p>17th Oct, 15</p>
                    </td>
                    <td className="member">
                      <figure>
                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/584938/people_8.png" />
                      </figure>
                      <div className="member-info">
                        <p>Myrtle Erickson</p>
                      </div>
                    </td>
                    <td className="status">
                      <span className="status-text status-red">Blocked</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>New Dashboard</p>
                    </td>
                    <td>
                      <p>17th Oct, 15</p>
                    </td>
                    <td className="member">
                      <figure>
                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/584938/people_8.png" />
                      </figure>
                      <div className="member-info">
                        <p>Myrtle Erickson</p>
                      </div>
                    </td>
                    <td className="status">
                      <span className="status-text status-orange">
                        In progress
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>New Dashboard</p>
                    </td>
                    <td>
                      <p>17th Oct, 15</p>
                    </td>
                    <td className="member">
                      <figure>
                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/584938/people_8.png" />
                      </figure>
                      <div className="member-info">
                        <p>Myrtle Erickson</p>
                      </div>
                    </td>
                    <td className="status">
                      <span className="status-text status-blue">
                        Early stages
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>New Dashboard</p>
                    </td>
                    <td>
                      <p>17th Oct, 15</p>
                    </td>
                    <td className="member">
                      <figure>
                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/584938/people_8.png" />
                      </figure>
                      <div className="member-info">
                        <p>Myrtle Erickson</p>
                      </div>
                    </td>
                    <td className="status">
                      <span className="status-text status-orange">
                        In progress
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default Dashboard;
