import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import AdminLayout from "./../../../Layouts/AdminLayout";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useHistory } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const Kits = () => {
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = React.useState(true);
  const [tableData, setTableData] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [kits, setKits] = useState();
  const [statusFilter, setStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [perPage, setPerPage] = useState(10); // Rows per page state
  const [totalKits, setTotalKits] = useState(0); // Rows per page state

  const getAllKits = async (page = 1, limit = 10, search = "", status = "") => {
    try {
      setPending(true);
      const token = localStorage.getItem("TOKEN");
      // console.log("token", token);
      // var params = props.location.search.slice(5);
      const kitResponse = await getRequest(`/api/secure/kit/get-all`, token, {
        limit: limit,
        page: page,
        search: search,
        status: status,
      });
      // getKitGroup(kitResponse.result.data.kits);

      if (kitResponse?.result?.status === 200) {
        setTableData(kitResponse.result.data.kits);
        setTotalKits(kitResponse.result.data.totalKits);
        setKits(kitResponse.result.data.kits);
      }else{
        setTableData([]);
        setTotalKits([]);
        setKits([]);
      }
      
      setTableHead([
        {
          name: "Kit Name",
          // sortable: true,
          selector: (row) => row.name,
        },
        {
          name: "Bar Code",
          // sortable: true,
          selector: (row, index) => row.barCodeId,
        },
        {
          name: "Status",
          // sortable: true,
          selector: (row, index) => (
            <span className="status-text status-red">{row.status}</span>
          ),
        },
        {
          name: "Expire",
          // sortable: true,
          selector: (row) => moment(row.expire).format("DD-MM-YYYY"),
        },
        {
          name: "Date",
          // sortable: true,
          selector: (row) =>
            moment(row.manufacturingDate).format("DD-MM-YYYY"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          // minWidth: "50px",
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="del_btn ms-1"
                size="sm"
                variant="danger"
                onClick={() =>
                  deleteSingleKit(row._id, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);
    } catch (error) {
      console.log("Get All Users Error", error);
    } finally {
      setPending(false);
    }
  };
  const handleStatusFilter = (e) => {
    const statusValue = e.target.value;
    setStatusFilter(statusValue);
    setCurrentPage(1); // Reset to the first page when changing the status filter
    getAllKits(1, perPage, searchItem, statusValue);
  };

  const searchKits = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);
    setCurrentPage(1); // Reset to the first page when changing the search query
    getAllKits(1, perPage, searchValue, statusFilter);
  };
  const deleteSingleKit = async (kitId, row, index, column, id) => {
    try {
      setPending(true);
      const token = localStorage.getItem("TOKEN");
      const response = await postRequestForm(
        `/api/secure/kit/delete-kits`,
        token,
        {
          manufacturingDate: row?.manufacturingDate,
          groupId: row?.groupId,
          isDeleteOne: true,
          kitId,
        }
      );
      // console.log(response);
      if (response.result.status === 200) {
        toast.success("Kits has been archived!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        getAllKits();
      }
    } catch (error) {
      console.log("Deleting Kit Group Error", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllKits(page, perPage, searchItem, statusFilter);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1); // Reset to the first page when changing the number of rows per page
    getAllKits(1, newPerPage, searchItem, statusFilter);
  };

  useEffect(() => {
    getAllKits();
  }, []); // Trigger the API call when the current page, rows per page, status filter, or search item changes

  return (
    <AdminLayout>
      <section id="users_list" className="pt-5">
        <Container>
          <Row>
            <Col>
              <Form.Label>Search Kits</Form.Label>
              <FormControl
                type="text"
                placeholder="Search Kits"
                className="me-2"
                value={searchItem}
                onChange={searchKits}
              />
            </Col>
            <Col>
              <Form.Label>Status</Form.Label>
              <FormControl
                as="select"
                value={statusFilter}
                onChange={handleStatusFilter}
              >
                <option value="">All</option>
                <option value="Not Activated">Not Activated</option>
                <option value="Activated">Activated</option>
              </FormControl>
            </Col>
          </Row>
          <br />
          <br />
          {!pending ? (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalKits} // Assuming you have a total count in your API response
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default Kits;
