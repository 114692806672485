import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useState } from "react";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ThreeDots } from "react-loader-spinner";
import AdminLayout from "./../../Layouts/AdminLayout";
import moment from "moment/moment";
import { getRequest, putRequest, uploadURL } from "./../../api";
import PhoneInput from "react-phone-number-input";

const Profile = () => {
  const [userId, setUserId] = useState();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [insuranceInfo, setInsuranceInfo] = useState();
  const [genderInfo, setGenderInfo] = useState();
  const [eComunication, setEComunicationInfo] = useState();
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [medicalDoctorAddress, setMedicalDoctorAddress] = useState("");
  const [medicalDoctorNumber, setMedicalDoctorNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [memberNumber, setMemberNumber] = useState("");
  const [groupNumber, setGroupNumber] = useState("");
  const [password, setPassword] = useState("");

  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setProfileImage(imageList[addUpdateIndex].file);
  };
  // eComunication
  const eComunicationOptions = [
    { value: "phone", label: "Phone" },
    { value: "email", label: "Email" },
  ];
  const eComunicationChange = (selectedOption) => {
    setEComunicationInfo(selectedOption);
  };

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];
  const genderChange = (selectedOption) => {
    setGenderInfo(selectedOption);
  };

  const insuranceOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];
  const insuranceChange = (selectedOption) => {
    setInsuranceInfo(selectedOption);
  };
  const updateUserHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    console.log("e.target", e);
    formData.append("firstName", firstName);
    formData.append("middleName", middleName);
    formData.append("lastName", lastName);
    formData.append("suffix", suffix);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("companyName", companyName);
    formData.append("memberNumber", memberNumber);
    formData.append("groupNumber", groupNumber);
    if (password) {
      formData.append("password", password);
    }
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }
    formData.append("dob", moment(dateOfBirth).format("DD-MM-YYYY"));
    formData.append(
      "insuranceInformation",
      typeof insuranceInfo === "object" ? insuranceInfo.value : insuranceInfo
    );
    formData.append(
      "gender",
      typeof genderInfo === "object" ? genderInfo.value : genderInfo
    );
    formData.append(
      "electronicCommunication",
      typeof eComunication === "object" ? eComunication.value : eComunication
    );
    formData.append("_id", userId);
    console.log("formData", Object.fromEntries(formData));
    try {
      const response = await putRequest(
        "/api/secure/user/edit-profile",
        "",
        formData
      );

      // console.log("status", response);
      if (response?.result?.status === 200) {
        toast.success("User Updated", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        // window.location.assign("/Users");
      }
    } catch (error) {
      console.log("Create Profile APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getUserByID = async (userId) => {
    try {
      const response = await getRequest("/api/secure/user/profileById", "", {
        _id: userId,
      });

      if (response?.result?.status === 200) {
        const {
          firstName,
          middleName,
          lastName,
          suffix,
          email,
          phone,
          address,
          medicalDoctorAddress,
          medicalDoctorNumber,
          companyName,
          memberNumber,
          groupNumber,
          profileImage,
          electronicCommunication,
          gender,
          insuranceInformation,
          dob,
        } = response?.result?.data?.user;
        setFirstName(firstName ? firstName : "");
        setMiddleName(middleName ? middleName : "");
        setLastName(lastName ? lastName : "");
        setSuffix(suffix ? suffix : "");
        setEmail(email ? email : "");
        setPhone(phone ? phone : "");
        setAddress(address ? address : "");
        setMedicalDoctorAddress(
          medicalDoctorAddress ? medicalDoctorAddress : ""
        );
        setMedicalDoctorNumber(medicalDoctorNumber ? medicalDoctorNumber : "");
        setCompanyName(companyName ? companyName : "");
        setMemberNumber(memberNumber ? memberNumber : "");
        setGroupNumber(groupNumber ? groupNumber : "");
        if (profileImage) {
          setImages([
            {
              data_url: uploadURL + profileImage,
            },
          ]);
        }
        setEComunicationInfo(electronicCommunication);
        setGenderInfo(gender);
        setInsuranceInfo(insuranceInformation);
        const formattedDate = moment(dob, "DD-MM-YYYY");

        if (formattedDate.isValid()) {
          console.log(formattedDate);
          setDateOfBirth(formattedDate.toDate());
        }
      }
    } catch (error) {
      console.log("Get Profile APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const getLabelByValue = (options, value) => {
    const option = options?.find((option) => option.value === value);
    return option?.label;
  };
  useEffect(() => {
    if (localStorage.getItem("USER") === "") {
      history.push("/login");
    } else {
      let localUser = JSON.parse(localStorage.getItem("USER"));
      setUserId(localUser._id);
      getUserByID(localUser._id);
    }
  }, [userId]);

  return (
    <AdminLayout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updateUserHandler}
            >
              <Row className="bg-white py-3 m-0">
                <Col sm={12} className="text-center">
                  <ImageUploading
                    value={images}
                    onChange={onChangeImage}
                    dataURLKey="data_url"
                    maxNumber={1}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      isDragging,
                      dragProps,
                    }) => (
                      <div className="upload__image-wrapper mx-auto mb-5">
                        <button
                          type="button"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                        {imageList
                          ? imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img src={image["data_url"]} alt="" />
                                <div className="image-item__btn-wrapper">
                                  <button
                                    type="button"
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                  </button>
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    )}
                  </ImageUploading>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      name="middleName"
                      type="text"
                      placeholder="Middle Name"
                      value={middleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12} className="d-none">
                  <Form.Group className="mb-3">
                    <Form.Label>Suffix</Form.Label>
                    <Form.Control
                      name="suffix"
                      type="text"
                      placeholder="Suffix"
                      value={suffix}
                      onChange={(e) => setSuffix(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="email"
                      type="mail"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <PhoneInput
                      international
                      placeholder="Enter phone number"
                      value={phone}
                      country="US"
                      onChange={setPhone}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Gender</Form.Label>
                    <ReactSelect
                      placeholder="Select Gender"
                      onChange={genderChange}
                      options={genderOptions}
                      required
                      value={
                        typeof genderInfo === "object"
                          ? genderInfo
                          : genderInfo
                          ? {
                              value: genderInfo,
                              label: getLabelByValue(genderOptions, genderInfo),
                            }
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <DatePicker
                      selected={dateOfBirth}
                      onChange={(date) => {
                        setDateOfBirth(date);
                      }}
                      required
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      className="form-control w-100"
                      maxDate={new Date()}
                      customInput={
                        <Form.Control type="text" placeholder="Date of Birth" />
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      name="address"
                      type="text"
                      placeholder="Address"
                      required
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Consent to Electronic Communication</Form.Label>
                    <ReactSelect
                      placeholder="Select Electronic Communication"
                      onChange={eComunicationChange}
                      options={eComunicationOptions}
                      value={
                        typeof eComunication === "object"
                          ? eComunication
                          : eComunication
                          ? {
                              value: eComunication,
                              label: getLabelByValue(
                                eComunicationOptions,
                                eComunication
                              ),
                            }
                          : ""
                      }
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Medical Doctor Name</Form.Label>
                    <Form.Control
                      name="medicalDoctorAddress"
                      type="text"
                      placeholder="Medical Doctor Name"
                      value={medicalDoctorAddress}
                      onChange={(e) => setMedicalDoctorAddress(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Medical Doctor Phone</Form.Label>
                    <Form.Control
                      name="medicalDoctorNumber"
                      type="tel"
                      placeholder="Medical Doctor Phone"
                      value={medicalDoctorNumber}
                      onChange={(e) => setMedicalDoctorNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Insurance Information</Form.Label>
                    <ReactSelect
                      placeholder="Select Insurance Information"
                      onChange={insuranceChange}
                      options={insuranceOptions}
                      value={
                        typeof insuranceInfo === "object"
                          ? insuranceInfo
                          : insuranceInfo
                          ? {
                              value: insuranceInfo,
                              label: getLabelByValue(
                                insuranceOptions,
                                insuranceInfo
                              ),
                            }
                          : ""
                      }
                      required
                    />
                  </Form.Group>
                </Col>
                {insuranceInfo?.value == "yes" && insuranceInfo?.value ? (
                  <>
                    <Col xl={4} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          name="companyName"
                          type="text"
                          placeholder="Company Name"
                          required
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Member Number</Form.Label>
                        <Form.Control
                          name="memberNumber"
                          type="text"
                          placeholder="Member Number"
                          required
                          value={memberNumber}
                          onChange={(e) => setMemberNumber(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Group Number</Form.Label>
                        <Form.Control
                          name="groupNumber"
                          type="text"
                          placeholder="Group Number"
                          required
                          value={groupNumber}
                          onChange={(e) => setGroupNumber(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default Profile;
