import React from "react";
import { Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import AuthLayout from "./../../Layouts/AuthLayout";
import { Formik } from "formik";
import * as Yup from "yup";
import { postRequestForm } from "./../../api";

const Login = () => {
  const history = useHistory();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  return (
    <AuthLayout>
      <section className=" pb-2">
        <div
          className="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
          style={{
            
  background: "linear-gradient(179deg, #15bd98 36.03%, #0fabd6 104.15%)"
          }}
        >
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 text-center mx-auto">
                <h1 className="text-white mb-2 mt-5">Welcome back!</h1>
                <p className="text-lead text-white">
                  Please Login into your Account
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-lg-n10 mt-md-n11 mt-n10">
            <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
              <div className="card z-index-0">
                <div className="card-body">
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={LoginSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        const res = await postRequestForm(
                          `/api/auth/login`,
                          "",
                          values
                        );
                        if (res.result.status === 200) {
                          const response = res.result.data;
                          if (
                            response?.user &&
                            response?.user?.role === "admin"
                          ) {
                            const user = response?.user;
                            history.push({
                              pathname: `/OTP`,
                              state: { _id: user._id, pathToGo: "dashboard" },
                            });
                          } else {
                            alert("You are not allowed to login.");
                            history.push(`/login`);
                          }
                        }
                      } catch (err) {
                        alert("Please enter correct email or password");
                        console.log(`Error of login`, err.message);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="email">
                          <Form.Label className="text-dark">Email address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="password">
                          <Form.Label className="text-dark">Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                        </Form.Group>
                        <div className="mb-3">
                          <small>
                            <Link
                              to="/forgetPassword"
                              className="text-dark font-weight-bolder"
                            >
                              Forgot Password?
                            </Link>
                          </small>
                        </div>
                        <div id="status">
                          <div
                            className={`alert ${
                              (errors.email && touched.email) ||
                              (errors.password && touched.password)
                                ? ""
                                : "d-none"
                            } ${errors ? "alert-danger" : "alert-success"}`}
                            role="alert"
                          >
                            {errors.email && touched.email
                              ? errors.email
                              : errors.password && touched.password
                              ? errors.password
                              : ""}
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            // disabled={isSubmitting}
                            className="btn w-100"
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AuthLayout>
  );
};

export default Login;
