import React from "react";
import { Button, Container, FormControl } from "react-bootstrap";
import AdminLayout from "./../../../Layouts/AdminLayout";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useHistory } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
const Users = () => {
  const history = useHistory();
  const [tableHead, setTableHead] = useState();
  const [columns, setColumns] = useState([]);
  const [pending, setPending] = React.useState(true);
  const [tableData, setTableData] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [users, setUsers] = useState();

  const getAllUsers = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      // console.log("token", token);
      // var params = props.location.search.slice(5);
      const response = await getRequest(`/api/secure/user/get-all`, token);

      setTableData(response.result.data.users);
      setUsers(response.result.data.users);
      console.log("Get All Users Response", response);
      setTableHead([
        {
          name: "First Name",
          // sortable: true,
          selector: (row) => row.firstName,
        },
        {
          name: "Email",
          // sortable: true,
          selector: (row) => row.email,
        },
        {
          name: "Phone",
          // sortable: true,
          selector: (row) => row.phone,
        },
        {
          name: "Date",
          // sortable: true,
          selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          // minWidth: "50px",
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editSingleUser(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                className="del_btn ms-1"
                size="sm"
                variant="danger"
                onClick={() =>
                  deleteSingleUser(row._id, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);
      setPending(false);
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };
  const editSingleUser = async (userId, row, index, column, id) => {
    history.push({
      pathname: `/editSingleUser`,
      state: { userId },
    });
  };
  const deleteSingleUser = async (userId, row, index, column, id) => {
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await postRequestForm(
        `/api/secure/user/delete-user`,
        token,
        { userId }
      );
      // console.log(response);
      if (response.result.status === 200) {
        toast.success("Member Deleted", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        // var fadeTarget = document.querySelector(
        //   `.rdt_Table .rdt_TableRow#row-${index}`
        // );
        // var fadeEffect = setInterval(function () {
        //   if (!fadeTarget.style.opacity) {
        //     fadeTarget.style.opacity = 1;
        //   }
        //   if (fadeTarget.style.opacity > 0) {
        //     fadeTarget.style.opacity -= 0.1;
        //   } else {
        //     if (fadeTarget.style.opacity == 0) {
        //       fadeTarget.style.display = "none";
        //       clearInterval(fadeEffect);
        //     }
        //   }
        // }, 5);

        getAllUsers();
      }
    } catch (error) {
      console.log("Get Site Setting Error", error);
    }
  };
  const searchUsers = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);

    if (searchValue === "") {
      setTableData(users); // Show all categories when the search input is empty
    } else {
      const searchData = users.filter(
        (val) =>
          val.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          val.email.toLowerCase().includes(searchValue.toLowerCase())
      );
      setTableData(searchData);
    }
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <AdminLayout>
      <section id="users_list" className="pt-5">
        <Container>
          <div className="d-flex">
            <FormControl
              type="text"
              placeholder="Search Users"
              className="me-2"
              value={searchItem}
              onChange={searchUsers}
            />
          </div>
          <br />
          <br />
          {!pending ? (
            <DataTable
              columns={tableHead}
              data={_.sortBy(tableData, "total").reverse()}
              style={{
                borderRadius: "10px",
              }}
              pagination
              progressPending={pending}
            />
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default Users;
