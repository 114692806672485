import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import AdminLayout from "./../../../Layouts/AdminLayout";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faListOl,
  faPencilAlt,
  faPrint,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useHistory, useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { debounce } from "underscore";
const KitGroups = () => {
  const location = useLocation();
  const history = useHistory();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(true);
  const [confirmPrint, setConfirmPrint] = useState(false);
  const [tableData, setTableData] = useState();
  const memoizedTableHead = useMemo(() => [
    {
      name: "Group Name",
      // sortable: true,
      selector: (row) => row.name,
    },
    {
      name: "Internal Number",
      // sortable: true,
      selector: (row, index) => row.internalNumber,
    },
    // {
    //   name: "Printed",
    //   // sortable: true,
    //   selector: (row) => (row.isPrinted ? "Yes" : "No"),
    // },
    {
      name: "Date",
      // sortable: true,
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
    },
    {
      name: "Actions",
      button: true,
      right: true,
      grow: 2,
      minWidth: "200px",
      sortable: false,
      cell: (row, index, column, id) => (
        <>
          {/* <Button
            className="edit_btn me-1"
            size="sm"
            variant="success"
            onClick={() => {
              history.push({
                pathname: `/printQR`,
                state: { _id: row._id },
              });
            }}
          >
            <FontAwesomeIcon icon={faPrint} />
          </Button> */}
          <Button
            className="edit_btn me-1"
            size="sm"
            variant="success"
            onClick={() => {
              history.push({
                pathname: `/addkitsByGroup`,
                state: { _id: row._id },
              });
            }}
          >
            <FontAwesomeIcon icon={faListOl} />
          </Button>
          {/* <Button
            className="edit_btn me-1"
            size="sm"
            variant="info"
            onClick={() => {
              history.push({
                pathname: `/updateKitsByGroup`,
                state: { _id: row._id },
              });
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button> */}
          <Button
            className="edit_btn me-1"
            size="sm"
            variant="primary"
            onClick={() => editKitGroup(row._id, row, index, column, id)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            className="del_btn"
            size="sm"
            variant="danger"
            onClick={() => deleteKitGroup(row._id, row, index, column, id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      ),
    },
  ]);
  const getAllKitGroups = useCallback(async (searchValue = "") => {
    try {
      const token = localStorage.getItem("TOKEN");
      const kitResponse = await getRequest(`/api/secure/kit/kit-groups`, token);
      if (searchValue) {
        const filteredData = kitResponse.result.data.kitGroups.filter((group) =>
          group.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setTableData(filteredData);
      } else {
        setTableData(kitResponse.result.data.kitGroups);
      }
      setTableHead(memoizedTableHead);
      setPending(false);
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  });
  const debouncedGetAllKitGroups = debounce((searchValue) => {
    getAllKitGroups(searchValue);
  }, 500);
  const handleSearch = (e) => {
    debouncedGetAllKitGroups(e.target.value);
  };
  const editKitGroup = async (kitGroupId, row, index, column, id) => {
    history.push({
      pathname: `/editKitGroup`,
      state: { _id: kitGroupId },
    });
  };
  const deleteKitGroup = async (kitGroupId, row, index, column, id) => {
    try {
      setPending(true);
      const token = localStorage.getItem("TOKEN");
      const response = await postRequestForm(
        `/api/secure/kit/delete-kit-group`,
        token,
        { _id: kitGroupId }
      );
      // console.log(response);
      if (response.result.status === 200) {
        setPending(false);
        getAllKitGroups();
        toast.success("Kit Group has been Deleted", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Deleting Kit Group Error", error);
    }
  };
  const handleClose = () => setConfirmPrint(false);
  const handlePrintConfirm = async () => {
    try {
      setPending(true);
      const token = localStorage.getItem("TOKEN");
      const response = await postRequestForm(
        `/api/secure/kit/print-kit-group`,
        token,
        { _id: location.state?.groupId }
      );
      // console.log(response);
      if (response.result.status === 200) {
        setPending(false);
        setConfirmPrint(false);
        history.push({ pathname: "/kitGroups"});

        toast.success("Kit Group has been Printed", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    debouncedGetAllKitGroups();
    setConfirmPrint(location.state?.confirmPrint ? true : false);
  }, [location]);

  return (
    <AdminLayout>
      <section id="users_list" className="pt-5">
        <Container>
          {!pending ? (
            <>
              {/* <Row>
                <Col xl={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-dark">
                      <strong>Serach</strong>
                    </Form.Label>
                    <Form.Control type="text" onChange={handleSearch} />
                  </Form.Group>
                </Col>
              </Row> */}
              <DataTable
                columns={tableHead}
                data={_.sortBy(tableData, "total").reverse()}
                style={{
                  borderRadius: "10px",
                }}
                pagination
                progressPending={pending}
              />
            </>
          ) : (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
          {confirmPrint && (
            <>
              <Modal
                show={confirmPrint}
                backdrop="static"
                keyboard={false}
                centered
                onHide={handleClose}
              >
                <Modal.Header>
                  <Modal.Title>Please confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Have you successfully printed the QR of Kits?
                </Modal.Body>
                <Modal.Footer className=" d-flex justify-content-between align-items-center flex-column-reverse">
                  <Button
                    className="w-100"
                    variant="primary"
                    onClick={handlePrintConfirm}
                  >
                    Yes
                  </Button>
                  <Button
                    className="w-100"
                    type="button"
                    variant="secondary"
                    onClick={handleClose}
                  >
                    No
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default KitGroups;
