import React from "react";
import AdminLayout from "./../../../Layouts/AdminLayout";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { postRequestForm } from "../../../api";
import { useState } from "react";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import ColorShadesTintsPicker from "./../../../Components/Admin/ColorShadesTintsPicker";
import ReactSelect from "react-select";
import TimePicker from "react-time-picker";
import { ThreeDots } from "react-loader-spinner";

const AddKitGroup = () => {
  const [isLoading, setLoading] = useState(false);
  const [colors, setColors] = useState([]);
  const [editor, setEditor] = useState();
  const [StepsEditor, setStepsEditor] = useState([]);
  const [steps, setSteps] = useState([]);
  const [testTime, setTestTime] = useState("00:00:00");
  const [age, setAge] = useState();

  const ageOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];
  const ageOptionChange = (selectedOption) => {
    setAge(selectedOption);
  };
  const onChangeEditorHandle = (editorState) => {
    setEditor(editorState);
  };
  const addRowTable = () => {
    const addStep = {
      content: "",
    };
    setSteps([...steps, addStep]);
  };
  const removeRowTable = (index) => {
    const StepsEditorRow = [...StepsEditor];
    StepsEditorRow.splice(index, 1);
    setStepsEditor(StepsEditorRow);
    const stepRow = [...steps];
    stepRow.splice(index, 1);
    setSteps(stepRow);
  };

  const addRowTableForColors = () => {
    const addColor = {
      baseColor: "",
      colorResultCase: "",
    };
    setColors([...colors, addColor]);
  };
  const removeRowTableForColors = (rowIndex) => {
    let colorRows = [...colors];
    colorRows.splice(rowIndex, 1);
    setColors(colorRows);
  };
  const onChangeResultColorHandler = (index, baseColor) => {
    const color = [...colors];
    color[index].baseColor = baseColor;
    setColors(color);
  };
  const colorCaseOptions = [
    { value: "pass", label: "Pass" },
    { value: "fail", label: "Fail" },
    { value: "partial_pass", label: "Partially Pass" },
    { value: "partial_fail", label: "Partially Fail" },
  ];
  const colorCaseChange = (selectedOption, index) => {
    const color = [...colors];
    color[index].colorResultCase = selectedOption.value;
    setColors(color);
  };
  const onChangeStepEditorHandle = (index, editorState) => {
    const StepEditor = [...StepsEditor];
    StepEditor[index] = editorState;
    setStepsEditor(StepEditor);
    const step = [...steps];
    step[index].content = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setSteps(step);
  };
  const CreateKitGroupHandler = async (e) => {
    e.preventDefault();
    // console.log({
    //   name: e.target[0].value,
    //   description: draftToHtml(convertToRaw(editor.getCurrentContent())),
    //   internalNumber: e.target[1].value,
    //   testTime: testTime,
    //   steps: steps,
    //   resultCodes: colors,
    // });
    try {
      setLoading(true);
      const token = localStorage.getItem("TOKEN");
      const response = await postRequestForm(
        "/api/secure/kit/create-kit-group",
        token,
        {
          name: e.target[0].value,
          description: draftToHtml(convertToRaw(editor.getCurrentContent())),
          internalNumber: e.target[1].value,
          testTime: testTime,
          steps: steps,
          resultCodes: colors,
          ageLimit: age?.value,
        }
      );

      // console.log("status", response);
      if (response?.result?.status === 200) {
        toast.success("Kit Group Created", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        window.location.assign("/kitGroups");
      }
    } catch (error) {
      console.log("Create Group APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminLayout>
      <section id="add_kit_group_form_section" className="py-5 ">
        <Container>
          {isLoading ? (
            <div className="loadingContainer text-center d-flex justify-content-center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={CreateKitGroupHandler}
            >
              <Row className="bg-white py-3 m-0">
                <>
                  <Col xl={6} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        placeholder="Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={6} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Internal Number</Form.Label>
                      <Form.Control
                        name="internalNumber"
                        type="text"
                        placeholder="Internal Number"
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={6} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Test Timer Duration (minutes:seconds)
                      </Form.Label>
                      <TimePicker
                        disableClock
                        required
                        format={"m:s"}
                        onChange={setTestTime}
                        value={testTime}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={6} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Age Limit 18+ ( Yes / No )</Form.Label>
                      <ReactSelect
                        placeholder="Is 18+?"
                        onChange={ageOptionChange}
                        options={ageOptions}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12} className="bg-light py-3">
                    <Form.Label>Kit Description</Form.Label>
                    <Editor
                      editorState={editor}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      onEditorStateChange={onChangeEditorHandle}
                    />
                  </Col>
                  <Col sm={6} className="py-3">
                    <Form.Label>Kit Step</Form.Label>
                    <Table
                      id="kitStepTable"
                      responsive
                      striped
                      className="dynamic_table"
                    >
                      <tbody>
                        {steps ? (
                          steps.map((rowsData, index) => (
                            <tr key={index}>
                              <td>
                                <Table className="bg-white m-0">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Form.Label>
                                          Setp {index + 1} Content
                                        </Form.Label>
                                        <Editor
                                          editorState={StepsEditor[index]}
                                          wrapperClassName="wrapper-class"
                                          editorClassName="editor-class"
                                          toolbarClassName="toolbar-class"
                                          onEditorStateChange={(
                                            editorState
                                          ) => {
                                            onChangeStepEditorHandle(
                                              index,
                                              editorState
                                            );
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </td>
                              <td>
                                <Button
                                  className="btn-danger"
                                  onClick={() => removeRowTable(index)}
                                >
                                  <FontAwesomeIcon icon={faMinus} />
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={2}>
                            <div className="d-flex justify-content-between">
                              <p className="m-0">Create New Step</p>
                              <Button
                                className="btn-primary"
                                onClick={() => addRowTable(steps)}
                              >
                                <FontAwesomeIcon icon={faAdd} />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </Col>
                  <Col sm={6} className="py-3">
                    <Form.Label>Result Colors</Form.Label>
                    <Table
                      id="kitResultColorTable"
                      responsive
                      striped
                      className="dynamic_table"
                    >
                      <tbody>
                        {colors ? (
                          colors.map((rowsData, index) => (
                            <tr key={index}>
                              <td>
                                <Table className="bg-white m-0">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Form.Label>
                                          Color {index + 1}
                                        </Form.Label>
                                        <ColorShadesTintsPicker
                                          value={colors[index]}
                                          onChangeColor={(color) => {
                                            onChangeResultColorHandler(
                                              index,
                                              color
                                            );
                                          }}
                                        />
                                        <Form.Label>
                                          Color {index + 1} Case
                                        </Form.Label>
                                        <ReactSelect
                                          placeholder="Select Gender"
                                          onChange={(selectedOption) => {
                                            colorCaseChange(
                                              selectedOption,
                                              index
                                            );
                                          }}
                                          options={colorCaseOptions}
                                          required
                                          defaultValue={{
                                            value:
                                              colors[index].colorResultCase,
                                            label:
                                              colors[index].colorResultCase ==
                                              "pass"
                                                ? "Pass"
                                                : colors[index]
                                                    .colorResultCase == "fail"
                                                ? "Fail"
                                                : colors[index]
                                                    .colorResultCase ==
                                                  "partial_pass"
                                                ? "Partially Pass"
                                                : colors[index]
                                                    .colorResultCase ==
                                                  "partial_fail"
                                                ? "Partially Fail"
                                                : "",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </td>
                              <td>
                                <Button
                                  className="btn-danger"
                                  onClick={() => removeRowTableForColors(index)}
                                >
                                  <FontAwesomeIcon icon={faMinus} />
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={2}>
                            <div className="d-flex justify-content-between">
                              <p className="m-0">Create New Color Case</p>
                              <Button
                                className="btn-primary"
                                onClick={() => addRowTableForColors(steps)}
                              >
                                <FontAwesomeIcon icon={faAdd} />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </Col>
                  <Col sm={12} className="py-3">
                    {/* <ColorShadesTintsPicker /> */}
                  </Col>
                </>

                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Create Group</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </AdminLayout>
  );
};

export default AddKitGroup;
