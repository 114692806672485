import React from "react";
import siteSettings from "../../Constants/SiteSettings";

export const Footer = () => {
  return (
    <footer className="footer pb-3 py-5">
      <div className="container">
        <div className="row">
          <div className="col-8 mx-auto text-center mt-1">
            <p className="mb-0 text-secondary">
              {siteSettings.Copyright_text}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
