import { useHistory } from "react-router-dom";

const Logout = () => {
  const history = useHistory();
  localStorage.removeItem("TOKEN");
  localStorage.removeItem("ROLE");
  localStorage.removeItem("USER");
  localStorage.clear();
  history.push("/login");
};

export default Logout;
