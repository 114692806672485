import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import AuthLayout from "./../../Layouts/AuthLayout";
import { Formik } from "formik";
import * as Yup from "yup";
import { postRequestForm } from "../../api";
const SetNewPassword = (props) => {
  let _id = '';
  const location = useLocation();
  const history = useHistory();
  if (location?.state?._id === "") {
    history.push("/login");
  } else {
    _id = location?.state?._id;
  }
  const SetNewPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
  });
  return (
    <AuthLayout>
      <section className=" mb-7 pb-2">
        <div
          className="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
          style={{
            background: "linear-gradient(179deg, #15bd98 36.03%, #0fabd6 104.15%)"

          }}
        >
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 text-center mx-auto">
                <h1 className="text-white mb-2 mt-5">New Password</h1>
                <p className="text-lead text-white">
                  Please enter new password to log into your account.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-lg-n10 mt-md-n11 mt-n10">
            <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
              <div className="card z-index-0">
                <div className="card-body">
                  <Formik
                    initialValues={{ password: "" }}
                    validationSchema={SetNewPasswordSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        const res = await postRequestForm(
                          `/api/auth/reset-password`,
                          "",
                          { _id, password:values.password }
                        );
                        if (res?.result?.status === 200) {
                          alert("Password has been Updated successfully");
                          history.push('/login')
                        } else {
                          alert("Error");
                        }
                      } catch (err) {
                        alert("Please enter correct email or password");
                        console.log(`Error of login`, err.message);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="password">
                          {/* <Form.Label>Password</Form.Label> */}
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                        </Form.Group>
                        <div id="status">
                          <div
                            className={`alert ${
                              errors.password && touched.password
                                ? ""
                                : "d-none"
                            } ${errors ? "alert-danger" : "alert-success"}`}
                            role="alert"
                          >
                            {errors.password && touched.password
                              ? errors.password
                              : ""}
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn w-100"
                          >
                            Set Password
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AuthLayout>
  );
};

export default SetNewPassword;
