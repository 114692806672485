import React, { useEffect, useState } from "react";
import Color from "color";
import { GithubPicker } from "react-color";

function ColorShadesTintsPicker({ onChangeColor, value }) {
  const [baseColor, setBaseColor] = useState(
    value.baseColor ? value.baseColor : "#000000"
  );
  const [ShowPicker, setShowPicker] = useState(false);
  // const [shades, setShades] = useState(value.baseColor ? value.shades : []);
  // const [tints, setTints] = useState(value.baseColor ? value.tints : []);

  const handleBaseColorChange = (color, event) => {
    setBaseColor(color.hex);
    onChangeColor(color.hex);
    // generateShadesTints(color.hex);
  };

  // const generateShadesTints = (color) => {
  //   const shades = generateShades(color);
  //   const tints = generateTints(color);
  //   setShades(shades);
  //   setTints(tints);
  //   onChangeColor(color, shades, tints);
  // };

  // const generateShades = (baseColor) => {
  //   let shades = [];
  //   shades.length = 0;
  //   for (let i = 1; i <= 10; i++) {
  //     //   if (
  //     //     Color(baseColor)
  //     //       .darken(i * 0.1)
  //     //       .hex() != "#000000"
  //     //   ) {
  //     shades.push(
  //       Color(baseColor)
  //         .darken(i * 0.1)
  //         .hex()
  //     );
  //     //   }
  //   }
  //   return shades;
  // };

  // const generateTints = (baseColor) => {
  //   let tints = [];
  //   tints.length = 0;
  //   for (let i = 1; i <= 10; i++) {
  //     if (
  //       Color(baseColor)
  //         .lighten(i * 0.1)
  //         .hex() != "#FFFFFF"
  //     ) {
  //       tints.push(
  //         Color(baseColor)
  //           .lighten(i * 0.1)
  //           .hex()
  //       );
  //     }
  //   }
  //   return tints;
  // };
  const handlePicker = () => {
    setShowPicker(!ShowPicker);
  };
  useEffect(() => {
    console.log("value", value);
    // generateShadesTints(baseColor);
  }, [baseColor]);

  return (
    <div className="color-shades-tints-picker flex-column justify-content-start align-items-start">
      <div className="color-shades-tints-picker-input color-picker">
        <div
          style={{
            padding: "10px",
            backgroundColor: baseColor,
          }}
          onClick={handlePicker}
        >
          <p
            style={{
              textShadow: "0 0 3px black",
            }}
            className="m-0 text-light"
          >
            Pick a Color
          </p>
        </div>
        {ShowPicker ? (
          <GithubPicker
            triangle="hide"
            color={baseColor}
            onChangeComplete={handleBaseColorChange}
          />
        ) : null}
      </div>
      {/* <div className="color-shades-tints-picker-input">
        <label>Shades:</label>
        <div className="shades-container">
          {shades.map((shade) => (
            <div
              key={shade}
              className="shade"
              style={{ backgroundColor: shade }}
            ></div>
          ))}
        </div>
      </div>
      <div className="color-shades-tints-picker-input">
        <label>Tints:</label>
        <div className="tints-container">
          {tints.map((tint) => (
            <div
              key={tint}
              className="tint"
              style={{ backgroundColor: tint }}
            ></div>
          ))}
        </div>
      </div> */}
    </div>
  );
}

export default ColorShadesTintsPicker;
